@media only screen and (min-width: 1400px) and (max-width: 4000px) {

}

@media only screen and (min-width: 767px) and (max-width: 991px) {

    body {
        padding-top: 80px;
    }

    .container {
        padding: 0 15px;
    }

    .space {
        padding: 35px 0;
    }

    .nav-btn {
        z-index: 105;
        width: 30px;
        height: 18px;
        cursor: pointer;
    }

    .nav-btn span {
        display: block;
        height: 4px;
        background-color: #4e5257;
        width: 30px;
        margin: 0 0 3px 0;

        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);

        -webkit-transition: .1s ease-in-out;
        -moz-transition: .1s ease-in-out;
        -o-transition: .1s ease-in-out;
        transition: .1s ease-in-out;

        -webkit-transform-origin: left center;
        -moz-transform-origin: left center;
        -o-transform-origin: left center;
        transform-origin: left center;
    }

    .nav-btn span:nth-child(1) {

    }

    .nav-btn span:nth-child(2) {

    }

    .nav-btn span:nth-child(3) {

    }

    .nav-btn.open {
        transition-duration: .3s;
    }

    .nav-btn.open span {
        box-shadow: none;
        background-color: #3c5a5d;
    }

    .nav-btn.open span:nth-child(1) {
        transform: rotate(45deg);
        margin-top: -6px;
        margin-left: 4px;
    }

    .nav-btn.open span:nth-child(2) {
        opacity: 0;
    }

    .nav-btn.open span:nth-child(3) {
        transform: rotate(-45deg);
        margin-top: 10px;
        margin-left: 4px;
    }


    header .logo {
        display: block;
    }

    header .logo img {
        height: 40px;
        margin: 0 auto;
        display: block;
    }

    .top {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
    }

    .top .top-item {
        margin-right: 22px;
    }

    header {
        position: fixed;
        top: 32px;
        border: 0;
        box-shadow: 0 7px 10px 0 rgba(162, 162, 162, .12),
        0 0 1px 0 rgba(144, 144, 144, .25);
    }

    header.fixed {
        position: fixed;
        top: 32px;
        padding: 10px 0;
        border: 0;
    }

    header.fixed .logo img {
        height: 40px;
    }

    header.fixed ~ .page {
        padding-top: 0 !important;
    }

    .fix-phone {
        width: 50px;
        height: 50px;
        right: 15px;
        bottom: 15px;
    }

    .fix-phone i {
        font-size: 26px;
        line-height: 51px;
    }

    nav .search {
        margin: 15px auto 30px;
        width: 300px;
    }

    .search.expand {
        width: 400px;
        margin-right: auto;
    }

    nav {
        position: fixed;
        top: 90px;
        left: 0;
        background-color: #fff;
        width: 100%;
        z-index: 115;
        padding: 0 10px;
        display: none;
        height: 100%;
        overflow: auto;
    }

    nav.open {
        display: block;
    }

    nav .menu-heading {
        display: none;
    }

    .thankyou h1 {
        font-size: 26px;
        line-height: 28px;
        margin: 0 0 10px 0;
    }

    .categories .holder-sm-category,
    .categories .holder-category {
        margin: 0 0 30px 0;
    }

    .newsletter img {
        margin: 0 0 15px 0;
    }

    .user-widgets .widget-item .cart-sum {
        display: none;
    }

    .user-widgets .widget-item {
        padding: 0 7px;
    }

    .profile h1 {
        margin: 0 0 25px 0;
        font-size: 30px;
        line-height: 30px;
    }


    .profile .order-details .val {
        margin: 0 0 10px 0;
        line-height: 18px;
    }

    .profile .product-price {
        text-align: left;
        margin: 5px 0 0 0;
        font-weight: bold;
        font-size: 14px;
        color: #000;
    }

    .profile-orders .order-num {
        padding: 0 0 10px 0;
        font-size: 14px;
        line-height: 20px;
    }

    .profile .order-products {
        border-top: 0;
    }

    .profile .order-products .product-row:first-of-type {
        padding-top: 0;
    }

    .profile .order-details {
        margin: 0 0 20px 0;
    }

    .profile .order-products .product-row .product-name {
        margin: 0;
    }

    .profile-address-list .btn {
        margin: 20px 0 0px 0;
    }


    .profile-address-list .btn {
        margin: 15px 0 0 0;
    }

    .mb-cart-heading {
        margin: 15px 0 0 0;
        color: #afafaf;
        display: block;
    }

    .thankyou .mb-cart-heading {
        display: none;
    }

    .cart .back-to-shop {
        margin: 10px 0 20px 0px;
    }

    .cart {
        padding: 45px 0 45px;
    }

    .sticky-top {
        top: 80px;
    }

    .order-delivery .delivery-method label {
        min-height: initial;
    }

    .order-delivery .delivery-type {
        position: relative;
        margin: 0 0 5px 0;
    }

    .order-delivery .delivery-types {
        margin: 30px 0 15px 0;
    }

    .checkout .payment-method {
        margin: 0 0 5px 0;
    }

    .blog-view h1 {
        margin: 0 0 25px 0;
        font-size: 36px;
        line-height: 42px;
    }

    .page-info h1 {
        font-size: 26px;
        line-height: 28px;
    }

    .discount-text {
        display: none;
    }

    .comparison-widget .comparison-num {
        left: 21px;
    }

    .user-widgets .widget-item .cart-num {
        left: 21px;
    }

    .products-list .product-item {
        margin: 0 0 30px 0;
    }

    .cart h1 {
        margin: 0 0 25px 0;
        font-size: 26px;
        line-height: 28px;
    }

    .products-list .product-item .product-action ul li {
        margin: 0 4px;
    }

    .products-list .product-item .product-action {
        position: inherit;
        top: 0;
        margin-top: 0;
        opacity: 1;
    }

    .products-list .product-item .product-action ul li button {
        width: 30px;
        height: 30px;
        padding: 0px;
    }

    .products-list .product-item .product-action ul li button img {
        height: 15px;
        padding: 0;
    }

    .products-list .product-item .product-desc {
        display: none;
    }

    .cart-login {
        margin: 0 0 25px 0;
    }

    .order-info {
        margin: 0 0 0px 0;
    }

    .invoice-info .invoice-toggle {
        margin: 0 0 5px 0;
    }

    .cart-headings {
        display: none;
    }

    .cart-list .remove-product {
        position: absolute;
        top: 50%;
        left: 10px;
        right: inherit;
        font-size: 16px;
        height: 28px;
        margin: -14px 0 0 0;
    }

    .checkout {
        padding: 45px 0 45px 0;
    }

    .owl-carousel .owl-nav {
        right: 10px;
    }

    .user-widgets .widget-item .fav-num {
        left: 25px;
    }

    nav .menu .menu-item .dropdown-btn {
        background: #f3f3f3;
    }

    .categories {
        padding: 0 0 35px 0;
    }

    .make-profilе label {
        margin-bottom: 5px;
    }


}

@media only screen and (min-width: 1200px) and (max-width: 1310px){
    .top .top-item a{
        padding: 0 5px;
    }
}
@media only screen and (min-width: 991px) and (max-width: 1310px){
    .top .container-fluid{
        padding: 0 15px;
    }
}

@media (min-width: 0px) and  (max-width: 991px){

    .category-menu .menu .menu-item {
        display: block;
    }

    .category-menu .menu .menu-item .menu-link {
        padding: 10px 60px 10px 45px;
    }

    .category-menu .menu .menu-item {
        display: block;
        border-bottom: 1px solid #d2d2d2;
    }

    .category-menu .menu .sub-menu .sub-menu-link {
        min-height: inherit;
        padding: 5px 0;
    }

    nav .menu-icon {
        display: block;
    }

    nav .menu .menu-item .dropdown-btn {
        display: block;
    }

    .category-menu .menu .menu-item.rel > .sub-menu {
        margin: inherit;
        width: inherit;
        position: inherit;
        background: inherit;
        z-index: inherit;
        padding-top: inherit;
        left: inherit;
        -webkit-column-count: inherit;
        -moz-column-count: inherit;
        column-count: inherit;
        padding-bottom: inherit;
        box-shadow: inherit;
    }

    .category-menu .menu .menu-item.menu-open > .sub-menu {
        display: block;
    }

    .category-menu .menu .menu-item .menu-link:hover,
    .category-menu .menu .menu-item.menu-open > .menu-link {
        color: #333;
        opacity: 1;
    }

    .category-menu .menu .menu-item .menu-link {
        margin: 0;
    }

    .category-menu .menu {
        text-align: left;
    }

    .search.expand {
        width: 85%;
        margin-right: auto;
    }

    nav .user-widgets {
        text-align: center;
        margin: 0 0 20px 0;
    }

    nav.open .search {
        display: table;
    }

    nav .cart-num {
        position: absolute;
        left: 26px;
        top: 11px;
        background-color: #F97F02;
        color: #e2e2e2;
        width: 13px;
        height: 13px;
        line-height: 12px;
        font-size: 10px;
        text-align: center;
        border-radius: 100%;
    }

    .general-sep {
        margin-bottom: 25px;
    }

    .cat-heading {
        font-size: 18px;
        padding: 0 0 5px 0;
    }

    nav .menu .menu-item .dropdown-btn {
        background: #f3f3f3;
    }

    nav .search {
        margin: 5px auto 10px;
        width: 250px;
        position: relative;
        right: auto;
    }
}

@media only screen and (min-width: 0px) and (max-width: 767px) {

    body {
        padding-top: 60px;
    }

    .container {
        padding: 0 15px;
    }

    .container-fluid {
        padding: 0 15px;
    }

    .space {
        padding: 35px 0;
    }

    .nav-btn {
        z-index: 105;
        width: 30px;
        height: 18px;
        cursor: pointer;
    }

    .nav-btn span {
        display: block;
        height: 4px;
        background-color: #4e5257;
        width: 30px;
        margin: 0 0 3px 0;

        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);

        -webkit-transition: .1s ease-in-out;
        -moz-transition: .1s ease-in-out;
        -o-transition: .1s ease-in-out;
        transition: .1s ease-in-out;

        -webkit-transform-origin: left center;
        -moz-transform-origin: left center;
        -o-transform-origin: left center;
        transform-origin: left center;
    }

    .nav-btn span:nth-child(1) {

    }

    .nav-btn span:nth-child(2) {

    }

    .nav-btn span:nth-child(3) {

    }

    .nav-btn.open {
        transition-duration: .3s;
    }

    .nav-btn.open span {
        box-shadow: none;
        background-color: #3c5a5d;
    }

    .nav-btn.open span:nth-child(1) {
        transform: rotate(45deg);
        margin-top: -6px;
        margin-left: 4px;
    }

    .nav-btn.open span:nth-child(2) {
        opacity: 0;
    }

    .nav-btn.open span:nth-child(3) {
        transform: rotate(-45deg);
        margin-top: 10px;
        margin-left: 4px;
    }


    header .logo {
        display: block;
    }

    header .logo img {
        height: 40px;
        margin: 0 auto;
        display: block;
    }

    header {
        position: fixed;
        top: 0;
        border: 0;
        box-shadow: 0 7px 10px 0 rgba(162, 162, 162, .12),
        0 0 1px 0 rgba(144, 144, 144, .25);
    }

    header.fixed {
        position: fixed;
        top: 0;
        padding: 10px 0;
        border: 0;
    }

    header.fixed .logo img {
        height: 40px;
    }

    header.fixed ~ .page {
        padding-top: 0 !important;
    }

    .fix-phone {
        width: 50px;
        height: 50px;
        right: 15px;
        bottom: 15px;
    }

    .fix-phone i {
        font-size: 26px;
        line-height: 51px;
    }

    nav {
        position: fixed;
        top: 60px;
        left: 0;
        background-color: #fff;
        width: 100%;
        z-index: 115;
        padding: 0 10px;
        display: none;
        height: 100%;
        overflow: auto;
        padding-bottom: 120px;
    }

    nav.open {
        display: block;
    }

    nav .menu-heading {
        display: none;
    }

    .intro {
        margin: 0 0 35px 0;
    }

    .order-delivery .delivery-method label {
        margin: 0 0 10px 0;
        min-height: initial;
    }

    .order-payment .payment-method label,
    .order-delivery .delivery-type label {
        padding: 10px 10px 10px 45px;
    }

    .order-delivery .delivery-type label .delivery-type-name {
        font-size: 14px;
        line-height: 16px;
    }

    .order-delivery .delivery-method label .delivery-name {
        font-size: 14px;
        line-height: 16px;
    }

    .thankyou h1 {
        font-size: 22px;
        line-height: 28px;
        margin: 0 0 10px 0;
    }

    .thankyou .total {
        margin-top: 25px;
    }

    .cart-login {
        margin: 0 0 15px 0;
    }

    .order-info {
        margin: 0 0 15px 0;
    }

    .order-delivery .delivery-type {
        position: relative;
        margin: 0 0 10px 0;
    }

    .products-list {
        padding: 25px 0 35px 0;
    }

    .products-list .product-item {
        box-shadow: 0px 2px 2px rgba(0, 0, 0, .1);
    }

    .owl-products-no-clone .owl-stage-outer .product-item,
    .owl-products .owl-stage-outer .product-item {
        margin: 0 7.5px 10px;
    }

    .owl-carousel .owl-nav {
        right: 15px;
    }

    .owl-theme .owl-nav [class*=owl-] {
        margin: 0;
        margin-left: 5px;
    }

    .comments .empty-comments img {
        height: 40px;
        margin: 0 0 10px 0;
    }

    .comments .empty-comments h3 {
        margin: 0 0 0px 0;
        font-size: 16px;
        line-height: 24px;
    }

    .page-info {
        padding: 0;
        margin: 0 0 15px 0;
    }

    #wo-breadcrumbs {
        padding: 0 0 7px 0;
    }

    .page-info h1 {
        font-size: 24px;
        line-height: 28px;
        margin: 15px 0 0 0;
    }

    .page-info ol {
        white-space: nowrap;
        overflow: scroll;
        padding-bottom: 10px;
    }

    .small-heading {
        font-size: 20px;
        line-height: 26px;
    }


    .similar-products {
        padding-bottom: 40px;
    }


    .product .product-img {
        margin: 0 0 20px 0;
    }

    .discount-text {
        display: none;
    }

    .empty-category img {
        height: 100px;
    }

    .product h1 {
        font-size: 22px;
        line-height: 30px;
        margin: 0 0 10px 0;
    }

    .product .product-func button {
        margin: 0 5px;
        display: inline-block;
    }

    .product .product-long-desc {
        margin: 20px 0 0 0;
        padding: 0 0 40px 0;
    }

    .product .product-info {
        padding: 0 0;
    }

    .product .product-long-desc .nav {
        border: 0;
    }

    .product .product-long-desc .nav a {
        width: 100%;
        text-align: center;
        border-bottom: 1px solid #d1d1d1;
    }

    .product .product-long-desc .technical-data {
        width: 100%;
    }

    .intro h2 {
        font-size: 30px;
        line-height: 32px;
    }

    .intro h3 {
        font-size: 16px;
        line-height: 22px;
    }

    .intro .special-price {
        font-size: 18px;
        line-height: 24px;
    }

    .intro .period {
        width: 100%;
    }

    .intro .carousel-caption {
        padding: 0 25px 0;
    }


    .categories .holder-sm-category,
    .categories .holder-category {
        margin: 0 0 15px 0;
    }

    .categories .holder-sm-category.space-category {
        margin-bottom: 15px;
    }

    .newsletter .heading {
        font-size: 20px;
        line-height: 24px;
        margin: 0 0 0 0;
    }

    .newsletter .newsletter-form {
        max-width: 100%;
        text-align: center;
    }

    .newsletter .form-control {
        margin: 0 0 15px 0;
    }

    .newsletter .checkbox {
        margin-bottom: 15px;
        text-align: left;
    }

    .newsletter .btn {
        margin: 0 0 15px 0;
        border-radius: 88px;
        width: 100%;
    }

    .newsletter .newsletter-social {
        margin: 20px 0 0 0;
        border: 0;
    }

    .newsletter .newsletter-social ul {
        margin: 10px 0 0 0;
    }

    .newsletter img {
        height: 45px;
        margin: 0 0 20px 0;
    }

    .user-widgets .widget-item .cart-sum {
        display: none;
    }

    .user-widgets .widget-item {
        padding: 0 5px;
    }

    .contacts .contact-form {
        padding: 0px 0 0px 0px;
        margin: 40px 0;
        border-left: 0;
    }

    .contacts .contact-info {
        padding: 40px 0 0 0;
        margin: 0;
    }

    .contacts .contact-info ul.contacts-list {
        padding: 0;
    }

    .contacts .contact-info .main-office {
        margin: 0 0 15px 0;
    }

    .contacts .contact-info .worktime {
        margin: 15px 0;
    }

    .contacts .offices .office {
        margin: 0 0 40px 0;
    }

    .contacts .offices .heading {
        text-align: left;
        margin: 0 0 30px 0;
    }

    .contacts .offices {
        padding: 30px 0 10px 0;
    }


    .blog-view h1 {
        font-size: 26px;
        line-height: 34px;
    }

    .blog-list .blog-item h3 a {
        font-size: 18px;
        line-height: 24px;
    }

    .blog-list .blog-item h3 {
        min-height: inherit;
    }

    .register h1 {
        font-size: 32px;
        line-height: 38px;
        margin: 0 0 15px 0;
        text-transform: uppercase;
    }

    .reset-password h1 {
        font-size: 32px;
        line-height: 38px;
        margin: 0 0 15px 0;
        text-transform: uppercase;
    }

    .forgot-password h1 {
        font-size: 32px;
        line-height: 38px;
        margin: 0 0 15px 0;
        text-transform: uppercase;
    }

    .login h1 {
        font-size: 32px;
        line-height: 38px;
        margin: 0 0 15px 0;
        text-transform: uppercase;
    }

    .products {
        padding-bottom: 35px;
    }

    .products-list .product-item .product-desc {
        display: none;
    }

    .products-list .product-item .product-action ul li {
        margin: 0 4px;
    }

    .products-list .product-item .product-prices {
        margin: 5px 0 10px;
    }

    .products-list .product-item .product-action {
        position: inherit;
        top: 0;
        margin-top: 0;
        opacity: 1;
    }

    .product .product-info .buy {
        font-size: 12px;
        padding: 0 0;
        letter-spacing: 0;
    }

    .products-list .product-item .product-action ul li button {
        width: 30px;
        height: 30px;
        padding: 0px;
    }

    .products-list .product-item .product-action ul li button img {
        height: 15px;
        padding: 0;
    }


    .cart h1 {
        margin: 0 0 10px 0;
        font-size: 20px;
        line-height: 24px;
    }

    .cart-delivery-hint {
        padding: 5px 15px;
        margin: 10px 0 10px 0px;
    }


    .blog-list .blog-item {
        margin: 0 0 35px 0;
    }

    .blog-list .blog-item-img a {
        top: 50%;
        left: 10%;
        width: 80%;
    }


    .cart-list .product-old-price {
        text-align: center;
    }

    .cart-list .product-img {
        text-align: center;
        padding-left: 20px;
    }

    .cart-list .cart-product-price {
        text-align: center;
        display: block;
    }

    .cart-list .remove-product {
        position: absolute;
        top: 50%;
        left: 15px;
        right: inherit;
        font-size: 16px;
        height: 28px;
        margin: -14px 0 0 0;
    }

    .mb-cart-heading {
        margin: 15px 0 0 0;
        color: #afafaf;
        display: block;
    }

    .cart-headings {
        display: none;
    }

    .order-delivery h3 {
        font-size: 16px;
        line-height: 22px;
        padding: 0 0 8px 0;
        margin: 0 0 15px 0;
    }

    .cart {
        padding: 25px 0 25px;
    }

    .cart .cart-list .cart-product-price {
        text-align: initial;
    }

    .cart .cart-list .thk-total .cart-product-price {
        text-align: right;
    }

    .cart-list .product-desc {
        margin: 0 0 15px 0;
    }

    .cart .back-to-shop {
        margin: 10px 0 20px 0;
    }


    .profile .order-details .val {
        margin: 0 0 10px 0;
        line-height: 18px;
    }

    .profile .product-price {
        text-align: left;
        margin: 5px 0 0 0;
        font-weight: bold;
        font-size: 14px;
        color: #000;
    }

    .profile-orders .order-num {
        padding: 0 0 10px 0;
        font-size: 14px;
        line-height: 20px;
    }

    .profile .order-products {
        border-top: 0;
    }

    .profile .order-products .product-row:first-of-type {
        padding-top: 0;
    }

    .profile .order-details {
        margin: 0 0 20px 0;
    }

    .profile .order-products .product-row .product-name {
        margin: 0;
    }

    .profile-address-list .btn {
        margin: 20px 0 0px 0;
    }


    .profile-address-list .btn {
        margin: 15px 0 0 0;
    }

    .profile {
        padding: 25px 0 50px 0;
    }

    .user-menu {
        margin: 0 0 30px 0;
    }

    .profile h1 {
        margin: 0 0 15px 0;
        font-size: 24px;
        line-height: 30px;
    }

    .profile .profile-content {
        padding: 0 0px;
    }

    .profile .form-group {
        margin: 0 0 15px 0;
    }

    .comparison-widget .comparison-num {
        left: 18px;
    }

    .user-widgets .widget-item .cart-num {
        left: 20px;
    }

    footer {
        padding: 50px 0 30px;
    }

    footer .logo img {
        height: 50px;
    }

    footer .logo {
        margin: 0 0 20px 0;
    }

    footer h3 {
        margin: 0 0 10px 0;
    }

    footer .about-company p {
        margin: 0 0 20px 0;
    }

    footer .about-company p:last-of-type {
        margin: 0 0 35px 0;
    }

    footer .footer-menu {
        margin: 0 0 35px 0;
    }

    footer .footer-delivery p {
        margin: 0 0 10px 0;
    }

    footer .footer-delivery {
        margin: 0 0 35px 0;
    }

    footer .copyright {
        padding: 25px 0 0 0;
        margin: 15px 0 0 0;
    }

    .categories {
        padding: 0 0 35px 0;
    }

    .main-msg {
        overflow: hidden;
    }

    .main-msg .lab {
        margin: 0 0 10px 0;
        display: table;
        line-height: 18px;
        padding: 8px 20px 8px 20px;
    }

    .main-msg .lab:before {
        display: none;
    }

    .make-profilе label {
        margin-bottom: 5px;
    }

    .compares .products-list .product-item .product-name {
        padding: 0 3px;
        font-size: 13px;
    }

    .compares .products-list .product-item .btn {
        height: 30px;
        font-size: 12px;
        line-height: 24px;
    }

    .compares .products-list .product-item {
        margin-bottom: 20px;
    }

    .compares .products-list .product-item .product-img img {
        padding: 0 3px;
        margin-bottom: 5px;
    }

    .compares .products-list .product-item .product-action {
        display: none;
    }

}

@media screen and (orientation: landscape)
and (min-device-width: 0)
and (max-device-width: 767px) {

}

@media screen and (orientation: landscape)
and (min-device-width: 768px)
and (max-device-width: 1024px) {

}
@media (max-width: 767px){
    .header-msg{
        position: sticky;
        top: 60px;
        z-index: 100;
    }
}

@media (min-width: 1800px) {
    .col-xxl {
        flex-basis: 0;
        -webkit-box-flex: 1;
        flex-grow: 1;
        min-width: 0;
        max-width: 100%
    }

    .col-xxl-auto {
        -webkit-box-flex: 0;
        flex: 0 0 auto;
        width: auto;
        max-width: 100%
    }

    .col-xxl-1 {
        -webkit-box-flex: 0;
        flex: 0 0 8.333333%;
        max-width: 8.333333%
    }

    .col-xxl-2 {
        -webkit-box-flex: 0;
        flex: 0 0 16.666667%;
        max-width: 16.666667%
    }

    .col-xxl-3 {
        -webkit-box-flex: 0;
        flex: 0 0 25%;
        max-width: 25%
    }

    .col-xxl-4 {
        -webkit-box-flex: 0;
        flex: 0 0 33.333333%;
        max-width: 33.333333%
    }

    .col-xxl-5 {
        -webkit-box-flex: 0;
        flex: 0 0 41.666667%;
        max-width: 41.666667%
    }

    .col-xxl-6 {
        -webkit-box-flex: 0;
        flex: 0 0 50%;
        max-width: 50%
    }

    .col-xxl-7 {
        -webkit-box-flex: 0;
        flex: 0 0 58.333333%;
        max-width: 58.333333%
    }

    .col-xxl-8 {
        -webkit-box-flex: 0;
        flex: 0 0 66.666667%;
        max-width: 66.666667%
    }

    .col-xxl-9 {
        -webkit-box-flex: 0;
        flex: 0 0 75%;
        max-width: 75%
    }

    .col-xxl-10 {
        -webkit-box-flex: 0;
        flex: 0 0 83.333333%;
        max-width: 83.333333%
    }

    .col-xxl-11 {
        -webkit-box-flex: 0;
        flex: 0 0 91.666667%;
        max-width: 91.666667%
    }

    .col-xxl-12 {
        -webkit-box-flex: 0;
        flex: 0 0 100%;
        max-width: 100%
    }

    .offset-xxl-0 {
        margin-left: 0
    }

    .offset-xxl-1 {
        margin-left: 8.333333%
    }

    .offset-xxl-2 {
        margin-left: 16.666667%
    }

    .offset-xxl-3 {
        margin-left: 25%
    }

    .offset-xxl-4 {
        margin-left: 33.333333%
    }

    .offset-xxl-5 {
        margin-left: 41.666667%
    }

    .offset-xxl-6 {
        margin-left: 50%
    }

    .offset-xxl-7 {
        margin-left: 58.333333%
    }

    .offset-xxl-8 {
        margin-left: 66.666667%
    }

    .offset-xxl-9 {
        margin-left: 75%
    }

    .offset-xxl-10 {
        margin-left: 83.333333%
    }

    .offset-xxl-11 {
        margin-left: 91.666667%
    }
    .d-xxl-flex {
        display: flex!important;
    }
    .d-xxl-inline {
        display: inline-block!important;
    }
}