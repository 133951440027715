.color-gray {
    color: gray;
}
.right-2 {
    right: 2em;
}

#configurator_cart_summary {
    gap: 8px;
    border-left: #e5e5e5;
    background: #f5f5f5;
}

#configurator_wrapper {
    font-family: 'Montserrat', sans-serif;
}

#filter_wrapper {
    border-top: 1px solid lightgray;
    border-bottom: 1px solid lightgray;
}

#element-theme-wrapper {
    display: flex;
    overflow-y: auto;
}
#element-theme-wrapper:after {
    /*position: absolute;*/
    /*background: linear-gradient(to left, #ffffff, rgba(255, 255, 255, 0));*/
    /*width: 80px;*/
    /*height: 100%;*/
    /*top: 0;*/
    /*right: 0;*/
    /*content: '';*/
}
.tiny-scroll::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

.tiny-scroll::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.tiny-scroll::-webkit-scrollbar-thumb {
    background: #888;
}

.tiny-scroll::-webkit-scrollbar-thumb:hover {
    background: #555;
}

#element-theme-wrapper img {
    max-width: 35px;
}

#elements-wrapper {
    display: flex;
    gap: 1em;
    flex-wrap: wrap;
    overflow-y: auto;
    max-height: 65vh;
    align-items: start;
    justify-content: center;
}

.cursor-pointer {
    cursor: pointer;
}
#preview_wrapper .result_piece,
#result_wrapper .result_piece {
    width: 60px;
    height: 60px;
    margin-right: -5px;
    z-index: 2;
}
#preview_wrapper .result_piece.left,
#result_wrapper .result_piece.left{
    transform: rotateZ(180deg);
    margin-top: -0.5em;
}
@media(max-width: 767px){
    #preview_wrapper .result_piece.left{
        transform: rotateZ(270deg);
        margin-top: 0;
    }
}
#preview_wrapper .result_piece .result_image.disabled,
#result_wrapper .result_piece .result_image.disabled{
    opacity: 0.5;
}
/*@media(max-width: 767px){*/
/*    #preview_wrapper .result_piece.disabled{*/
/*        height: 43px;*/
/*    }*/
/*}*/

#result_wrapper .result_piece .result_image {
    width: 100%;
}
#preview_wrapper .result_piece.double,
#result_wrapper .result_piece.double {
    width: 116px;
}
#result_wrapper .result_piece img.has_element_over {
    margin-top: -20%;
}

#preview_wrapper .result_piece.watchContainer,
#result_wrapper .result_piece.watchContainer {
    margin: 0 -1.5rem;
    width: 180px;
    height: 60px;
    align-items: center;
    display: flex;
    transform: rotate(90deg);
    z-index: 1;
}

#preview_wrapper .result_piece #watch-image,
#result_wrapper .result_piece #watch-image {
    pointer-events: none;
}

#preview_wrapper {
    align-items: center;
    height: calc(100vh - 60px);
}

.close_preview_btn {
    position: absolute;
    top: 1em;
    right: 1em;
    height: 3em;
    cursor: pointer;
}

.preview_btn {
    width: 20px;
    cursor: pointer;
}

body.preview {
    overflow: auto !important;
    padding: 0 !important;
}

body.preview header {
    display: none;
}

body.preview #preview_wrapper {
    padding-top: 1rem !important;
}

.element_theme {
    text-align: center;
    min-width: 66px;
    height: 66px;
    min-width: 75px;
    height: 75px;
    font-size: 12px;
    /*border: 1px solid gray;*/
    border-left: 1px solid lightgray;
    cursor: pointer;
    justify-content: center;
    display: flex;
    flex-flow: column;
    align-items: center;
}
.element_theme.small{
    min-width: 35px;
}
.element_theme img {
    max-width: 25px;
}

.element_theme span {
    font-size: 80%;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
}

.element_theme.active {
    background: #ebe7e7;
}

#overlay_background {
    width: 100%;
    height: 100%;
    background: black;
    opacity: 0.4;
    position: absolute;
    z-index: 10;
    cursor: pointer;
}

.single_element img {
    max-width: 80px;
}

.single_element img.dragged {
    opacity: 0.5;
}

.single_element.double img {
    max-width: 160px;
    margin-left: 0.5em;
    margin-right: 0.5em;
}

.single_element.out_of_stock img {
    opacity: 0.5;
}

.single_element.out_of_stock:after {
    content: '';
    position: absolute;
    width: 100%;
    border-top: 2px solid lightgray;
    transform: rotate(-30deg);
}

.single_element.double.out_of_stock:after {
    width: 90%;
    transform: rotate(-15deg);
    left: 10px;
}

.filter_element {
    width: 300px;
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
}

.filter_element:hover, .filter_element.active {
    background: black;
}

.filter_element.active p, .filter_element:hover p {
    color: white;
}

.filter_element.active:after {
    content: "\f00c";
    color: white;
    font-family: FontAwesome;
    font-size: 1.5em;
    font-weight: 100;
    margin-left: auto;
}

.filter_toggle {
    cursor: pointer;
    background: white;
    padding: 1em 0;
    height: 50px;
    display: flex;
    align-items: center;
}

.filter_toggle.active{
    background: #ebe7e7;
}

.filter_toggle.open {
    z-index: 9999;
}

.filter_toggle:not(.open):hover {
    background: #fbfbfb;
}

.filter_toggle .filter_image {
    max-width: 40px;
    border-radius: 25px;
    border: 1px solid lightgray;
    padding: 4px;
    object-fit: cover;
}

.filter_dropdown {
    position: absolute;
    background: white;
    z-index: 9999;
    left: 0;
    top: 50px;
}

.filter_dropdown .filter_heading {
    background: #f5f5f5;
    text-transform: uppercase;
}

.filter_dropdown .filter_heading .close_filter {
    float: right;
    width: 20px;
}

.filter_dropdown .base_image {
    max-width: 50px;
    float: left;
    margin-right: 1em;
}

.filter_toggle_title {
    text-transform: uppercase;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

#cart_wrapper {
    border-left: 1px solid lightgray
}

.cart_summary_heading {
    background: #f5f5f5;
    justify-content: space-between;
    display: flex;
    padding: 15px;
    text-transform: uppercase;
}

.cart_summary_heading img {
    transform: rotate(90deg);
    transition: transform 0.5s;
    width: 20px;
}

.cart_summary_body {
    overflow-y: auto;
    transition: height 0.5s;
    height: calc(100vh - 220px);
    padding-bottom: 60px;
}

.cart_summary_body .total-action {
    border: none;
}

#bundle_submit {
    border-radius: 0;
}

.cart_summary_heading.closed + .cart_summary_body {
    height: 0;
}

.cart_summary_heading.closed img {
    transform: rotate(0deg);
}

#cart_wrapper .product-name {
    font-size: 11px;
}

.out_of_stock_label {
    color: red;
}

#cart_wrapper .sticky-bottom {
    position: sticky;
    bottom: 0;
}

#element_detail_modal {
    display: none;
}

.remove-element-btn {
    position: absolute;
    top: calc(50% - 50px);
    color: red;
    cursor: pointer;
    z-index: 9999;
}

#pseudo_drag_image {
    position: absolute;
    display: none;
    width: 60px;
}
.js-range-slider{
    display: none;
}
.filter_content {
    overflow-y: auto;
    max-height: calc(100vh - 300px);
}
@media(max-height: 1100px){
    .filter_content{
        max-height: calc(100vh - 400px);
    }
}
.multi_action_wrapper{
    gap: 2em;
    margin-left: 15px;
}

.filter_dropdown {
    min-width: 260px;
}
.filter_wrapper{
    position: relative;
}
.filter_wrapper.active:before{
    content: '';
    position: absolute;
    width: 15px;
    height: 15px;
    background: #ed4245;
    top: -5px;
    right: -5px;
    border-radius: 25px;
}
@media (max-width: 1500px){
    .filter_toggle_title{
        font-size: 12px;
    }
    .multi_action_wrapper{
        height: 20px;
        gap: 15px;
        margin-left: 0;
    }
    .filter_dropdown .base_image {
        max-width: 45px;
        float: left;
        margin-right: 5px;
    }
    .filter_dropdown .base_image + p {
        font-size: 12px;
    }
    #cart_wrapper p, #cart_wrapper p strong{
        font-size: 12px;
    }
    #element-theme-wrapper img {
        max-width: 25px;
    }
}

@media (max-width: 991px) {
    .remove-element-btn {
        top: 5px;
    }
    #preview_wrapper .result_piece.watchContainer,
    #result_wrapper .result_piece.watchContainer {
        margin: 0 -1.5rem 0 -1.35em;
    }
    #preview_wrapper .result_piece.watchContainer {
        transform: rotate(-180deg);
        margin: 1em 0;
    }
    header {
        border-bottom: 1px solid lightgray;
    }

    #element-theme-wrapper {
        border-top: 1px solid #f5f5f5;
    }

    .element_theme {
        min-width: 50px;
        height: 50px;
        font-size: 10px;
    }

    .single_element {
        max-width: 60px;
        /*flex: 0 0 18%;*/
    }

    .single_element img,
    .single_element.double img {
        max-width: 100%;
    }

    .single_element.double {
        max-width: 120px;
        /*flex: 0 0 35%;*/
    }

    .filter_dropdown {
        width: 100vw;
    }

    .filter_toggle {
        padding: 1em 0;
        position: initial;
    }

    .filter_toggle_title {
        font-size: 9px;
    }

    .filter_toggle .filter_image {
        max-width: 30px;
    }

    .filter_element {
        width: 100%;
    }

    .filter_element p {
        text-align: left;
    }

    .filter_element.active:after {
        font-size: 1em;
    }

    #bundle_submit {
        font-size: 12px;
    }

    #configurator_dynamic_wrapper {
        display: flex;
        flex-flow: inherit;
    }

    #filter_wrapper {
        order: 0;
    }

    .filter_content {
        overflow-y: auto;
        max-height: calc(100vh - 200px);
    }

    #result_wrapper {
        order: 1;
        display: -webkit-box!important;
        overflow-y: auto;
        border-bottom: 1px solid lightgray;
    }

    #elements-wrapper {
        order: 2;
        gap: 1em;
        justify-content: left;
        max-height: 50vh;
        padding-right: 4em;
    }

    #elements-wrapper.selected_element {
        opacity: 0.5;
    }

    #element_detail_modal {
        display: block;
        position: fixed;
        z-index: 99;
        background: white;
        padding-bottom: 1em;
        left: 0;
        right: 0;
        top: 16%;
        width: 90%;
        margin: 0 auto;
    }

    #element_detail_modal img {
        max-width: 180px;
    }

    #cart_wrapper {
        position: fixed;
        top: 47px;
        background: white;
        width: 85%;
        right: 0;
        z-index: 9999;
        border: 0;
    }

    .cart_summary_body {
        padding-bottom: 60px;
        border-top: 1px solid lightgray;
    }

    #preview_wrapper {
        height: fit-content;
        flex-flow: column;
    }

    #preview_wrapper .result_piece {
        transform: rotate(90deg);
        height: fit-content;
        height: 33px;
        max-width: 10% !important;
    }
    #preview_wrapper .result_piece.watchContainer {
        max-width: 30% !important;
    }

    #preview_wrapper .result_piece.double {
        max-width: 20% !important;
        margin: 1.5em 0;
    }
    #preview_wrapper .result_piece.double .result_image {
        margin-top: -0.25em;
    }
    #preview_wrapper .result_piece.double.left .result_image {
        margin-top: 0.1em;
    }
    /*#preview_wrapper .result_piece.watchContainer,*/
    /*#result_wrapper .result_piece.watchContainer {*/
    /*    margin: 0 -0.5rem;*/
    /*}*/
}

@media (max-width: 380px) {
    .single_element {
        max-width: 55px;
    }

    .single_element.double {
        max-width: 106px;
    }
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    body {
        padding-top: 46px !important;
    }
}


.tutorial_overlay_wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: #000;
    z-index: 1000;
    opacity: .75;
    display: flex;
    flex-direction: row;
}
.tutorial_overlay_wrapper .tutorial_overlay_draggingLink {
    position: absolute;
}
.tutorial_overlay_wrapper.tutorial_overlay_compact.tutorial_overlay_horizontal .tutorial_overlay_draggingLink {
    top: 300px;
    left: 20%;
}
.tutorial_overlay_wrapper.tutorial_overlay_compact.tutorial_overlay_horizontal .tutorial_overlay_builder {
    flex: auto;
    position: relative;
}
.tutorial_overlay_wrapper.tutorial_overlay_compact .tutorial_overlay_dragTargetContainer {
    top: 110px;
    width: 100%;
    left: -7%;
    text-align: center;
}
.tutorial_overlay_wrapper.tutorial_overlay_compact .tutorial_overlay_dragTargetContainer img {
    height: 4em;
    width: 50%;
}
.tutorial_overlay_dragTargetTxt{
    position: absolute;
    top: 0;
    color: white;
    width: 100%;
}
.tutorial_overlay_dragTargetTxt h1{
    color: white;
}
.tutorial_overlay_wrapper .tutorial_overlay_builder .tutorial_overlay_dragTargetContainer {
    position: absolute;
    box-sizing: border-box;
}
.tutorial_overlay_wrapper .tutorial_overlay_draggingLink>svg {
    display: block;
    margin: auto;
    -webkit-animation: dragLinkAnimation 2s ease-in-out infinite;
    animation: dragLinkAnimation 2s ease-in-out infinite;
}
.tutorial_overlay_wrapper .tutorial_overlay_draggingArrow {
    position: absolute;
    left: 40%;
    top: -90px;
}
.tutorial_overlay_wrapper .tutorial_overlay_draggingArrow>svg {
    height: 120px;
}
.tutorial_overlay_wrapper .tutorial_overlay_tapLink {
    position: absolute;
}
.tutorial_overlay_wrapper.tutorial_overlay_compact.tutorial_overlay_horizontal .tutorial_overlay_tapLink {
    top: 59%;
    left: 47%;
}
.tutorial_overlay_wrapper .tutorial_overlay_tapLink>svg {
    display: block;
    margin: auto;
    -webkit-animation: pulseLinkAnimation 2s ease-in-out infinite;
    animation: pulseLinkAnimation 2s ease-in-out infinite;
}
.tutorial_overlay_wrapper .tutorial_overlay_tapLink .tutorial_overlay_tapTxt {
    line-height: 150%;
    top: -25px;
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    position: absolute;
    width: 300px;
    left: -100px;
    text-align: center;
}
.tutorial_overlay_wrapper.tutorial_overlay_compact.tutorial_overlay_horizontal .tutorial_overlay_summary {
    display: none;
}
@keyframes dragLinkAnimation {
    0% {
        -webkit-transform: rotate(0) translateY(0);
        transform: rotate(0) translateY(0);
        opacity: 1;
    }
    50% {
        opacity: 1;
    }
    80% {
        opacity: 0;
    }
    100% {
        -webkit-transform: rotate(15deg) translateY(-190px);
        transform: rotate(15deg) translateY(-190px);
        opacity: 0;
    }
}
@keyframes pulseLinkAnimation {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
    50% {
        -webkit-transform: scale(.9);
        transform: scale(.9);
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

@media(max-width: 767px){
    .tutorial_overlay_wrapper.tutorial_overlay_compact .tutorial_overlay_dragTargetContainer {
        top: 195px;
        width: 90%;
        left: 5%;
    }
    .tutorial_overlay_wrapper.tutorial_overlay_compact .tutorial_overlay_dragTargetContainer img {
        width: 100%;
    }
    .tutorial_overlay_wrapper.tutorial_overlay_compact.tutorial_overlay_horizontal .tutorial_overlay_draggingLink {
        top: 350px;
        left: 30px;
    }
}

#watch_configurator_info_message{
    position: absolute;
    top: 0;
    left: 0.75em;
    font-size: 1.5em;
    z-index: 999
}
@media (max-width: 767px) {
    #watch_configurator_info_message{
        top: 1em;
    }
}