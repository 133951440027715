/* base styles
================================================== */
* {
    margin: 0;
    padding: 0;
    outline: 0;
}

body,
html {
    height: 100%;
}

body {
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    line-height: 1.5;
    color: #333;
    font-weight: 400;
}

::selection {
    background: #000000;
    color: #fff;
}

::-moz-selection {
    background: #000000;
    color: #fff;
}

:focus {
    outline: 0
}

img {
    max-width: 100%;
    height: auto;
    display: inline-block;
    vertical-align: middle;
    border: none;
}

input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px white inset;
}

.space {
    padding: 30px 0;
}

.space-bot {
    padding-bottom: 30px;
}

/* typography
================================================== */
h1, h2, h3, h4, h5, h6 {
    font-family: 'Roboto', sans-serif;
    color: #000000;
}

h1 {
    font-size: 26px;
    line-height: 32px;
}

h2 {
    font-size: 22px;
    line-height: 28px;
}

h3 {
    font-size: 18px;
    line-height: 28px;
}

h4 {
    font-size: 18px;
    line-height: 28px;
}

h5 {
    font-size: 16px;
    line-height: 24px;
}

h6 {
    font-size: 16px;
    line-height: 24px;
}

strong {
    font-weight: 700;
}

a {
    color: #333;

    -webkit-transition: all .4s;
    -moz-transition: all .4s;
    -o-transition: all .4s;
    transition: all .4s;
}

a:hover,
a:active,
a:focus {
    color: #333;
    outline: 0;
    text-decoration: none;
}

p {
    color: #000000;
    margin-bottom: 20px;
    font-size: 14px;
    line-height: 1.5;
}

.heading {
    margin: 0 0 30px 0;
    font-size: 26px;
    line-height: 34px;
    color: #333;
    text-transform: uppercase;
    font-weight: 300;
    font-family: 'Montserrat', sans-serif;
    letter-spacing: 1.2px;
}

.small-heading {
    font-size: 24px;
    line-height: 26px;
    color: #000000;
    text-transform: uppercase;
    font-family: 'Montserrat', sans-serif;

}

.container-fluid {
    padding: 0 30px;
}

.button {
    font-family: 'Montserrat', sans-serif;
    margin: 0;
    cursor: pointer;
    display: inline-block;
    font-weight: 400;
    height: 48px;
    padding: 0 20px;
    line-height: 48px;
    font-size: 14px;
    border: 1px solid transparent;
    text-transform: uppercase;
    text-align: center;

    -webkit-transition: all .4s;
    -moz-transition: all .4s;
    -o-transition: all .4s;
    transition: all .4s;
}

.btn:visited,
.btn:active,
.btn.focus,
.btn:focus {
    outline: 0 !important;
    box-shadow: none !important;
}

.button-primary {
    background-color: #000;
    border-color: #000;
    color: #fff;
    outline: none !important;
}

.button-primary:hover {
    background-color: #fff !important;
    border-color: #000 !important;
    color: #333 !important;
}

.button-primary.focus,
.button-primary:focus,
.button-primary.disabled,
.button-primary:disabled,
.button-primary:not(:disabled):not(.disabled).active:focus,
.button-primary:not(:disabled):not(.disabled):active:focus,
.show > .button-primary.dropdown-toggle:focus,
.button-primary:not(:disabled):not(.disabled).active,
.button-primary:not(:disabled):not(.disabled):active,
.show > .button-primary.dropdown-toggle {
    background-color: #fff;
    border-color: #000;
    color: #000;
    box-shadow: none;
}

.button-secondary {
    background: #eaebea;
    border-color: #868686;;
    color: #333;
    outline: none !important;
}

.button-secondary:hover {
    background: #868686;
    border: 1px solid #868686;
    color: #eaebea;
}

.button-secondary.focus,
.button-secondary:focus,
.button-secondary.disabled,
.button-secondary:disabled,
.button-secondary:not(:disabled):not(.disabled).active:focus,
.button-secondary:not(:disabled):not(.disabled):active:focus,
.show > .button-secondary.dropdown-toggle:focus,
.button-secondary:not(:disabled):not(.disabled).active,
.button-secondary:not(:disabled):not(.disabled):active,
.show > .button-secondary.dropdown-toggle {
    background: #868686;
    border: 1px solid #868686;
    color: #eaebea;
    box-shadow: none;
}

.button-secondary-outline {
    background-color: transparent;
    border-color: #000000;
    color: #000000;
}

.button-secondary-outline:hover {
    border-color: #000000;
    background-color: #000000;
    color: #fff;
}

label {
    margin-bottom: 3px;
}

textarea.form-control {
    padding: 10px 15px;
    resize: none;
}

.form-control {
    height: 44px;
    padding: 0 15px;
    background-color: #fff;
    border-radius: 0;
    border: 1px solid #e3e2e2;
    font-size: 14px;
}

.form-control:focus {
    box-shadow: none;
    border-color: #ccc;
}

.form-icon .form-control {
    padding-left: 44px;
}

.form-icon {
    position: relative;
}

.form-icon img {
    position: absolute;
    left: 15px;
    max-height: 20px;
    max-width: 18px;
}

/*.container {*/
/*    max-width: 1800px;*/
/*    width: 100%;*/
/*    padding: 0 50px;*/
/*}*/


#flash-messages-container {
    margin-top: 20px;
}

.text-emphasise {
    color: #F92B8C;
}

.font-montserrat {
    font-family: 'Montserrat', sans-serif;
}

/*top*/
.top {
    background-color: #eaebea;
    border-bottom: 1px solid #e8e8e8;
    padding: 8px 0;
    z-index: 107;
    margin: 0 0 5px 0;
}

.top .top-item {
    display: inline-block;
    position: relative;
}

.top .top-item a {
    color: #333;
    display: block;
    font-size: 12px;
    line-height: 12px;
    padding: 0 12px;
    white-space: nowrap;
}

.top .top-item a:hover {
    text-decoration: underline;
}

.top .top-item.item-icon i {
    margin-right: 6px;
}

.top .top-item:after {
    content: "";
    height: 100%;
    width: 1px;
    position: absolute;
    top: 0;
    right: -3px;
    background-color: #ababab;
}

.top .top-item:last-of-type:after {
    display: none;
}


.top .top-bar p {
    margin-bottom: 0;
}

/*langs*/
.langs ul .lang-item {
    display: inline-block;
    position: relative;
}

.langs .lang-icon {
    margin-right: 10px;
}

.langs ul .lang-item .lang-item-link .nav-arrow {
    margin-left: 2px;
}

.langs ul .lang-item .lang-item-link {
    color: #fff;
    display: block;
    position: relative;
}

.langs ul > .lang-item:hover > .sub-lang {
    display: block;
}

.langs ul .sub-lang:before {
    content: "";
    width: 100%;
    height: 2px;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #000000;
}

.langs ul.sub-lang {
    background-color: #fff;
    position: absolute;
    left: 0;
    right: auto;
    margin-top: 10px;
    padding: 8px 0;
    display: none;

    -webkit-box-shadow: 0 15px 25px 0 rgba(0, 0, 0, .25);
    -moz-box-shadow: 0 15px 25px 0 rgba(0, 0, 0, .25);
    box-shadow: 0 15px 25px 0 rgba(0, 0, 0, .25);
}

.langs ul.sub-lang:after {
    content: "";
    height: 16px;
    width: 100%;
    position: absolute;
    top: -16px;
    left: 0;
}

.langs ul.sub-lang li:hover > ul.sub-lang {
    display: block;
}

.langs ul.sub-lang li > ul.sub-lang {
    top: 0;
    left: 0;
    display: none;
}

.langs ul .sub-lang .sub-lang-item {
    display: block;
    position: relative;
}

.langs ul .sub-lang .sub-lang-item-link {
    display: block;
    position: relative;
    padding: 5px 20px;
}

.langs ul .sub-lang .sub-lang-item-link:hover {
    color: #000000;
}


/*vouchers*/
.vouchers a {
    color: #fff;
    opacity: .7;
}

.vouchers a:hover {
    text-decoration: underline;
    opacity: 1;
}


/*delivery hint*/
.delivery-hint {
    background-color: #000000;
    padding: 8px 0;
    text-align: center;
    color: #eaeaea;
}

.delivery-hint span {
    margin: 0 10px;
    opacity: .7;
}


/*user widgets*/
.user-widgets {
    text-align: right;
}

.user-widgets.user-widgets-left {
    text-align: left;
}

.user-widgets.user-widgets-left .widget-item:first-of-type {
    padding-left: 0;
}

.user-widgets .widget-item {
    display: inline-block;
    padding: 0 11px;
    position: relative;
}

.user-widgets .widget-item .fav-num {
    position: absolute;
    left: 31px;
    top: -2px;
    background-color: #000000;
    color: #e2e2e2;
    width: 13px;
    height: 13px;
    line-height: 12px;
    font-size: 10px;
    text-align: center;
    border-radius: 100%;
}

.user-widgets .widget-item .cart-num {
    position: absolute;
    left: 26px;
    top: -2px;
    background-color: #333;
    color: #e2e2e2;
    width: 13px;
    height: 13px;
    line-height: 12px;
    font-size: 10px;
    text-align: center;
    border-radius: 100%;
}

.user-widgets .widget-item .cart-sum {
    display: inline-block;
    font-size: 12px;
    margin-left: 5px;
    position: relative;
    top: 5px;
}

.user-widgets .widget-item .icn {
    height: 22px;

    -webkit-transition: all .4s;
    -moz-transition: all .4s;
    -o-transition: all .4s;
    transition: all .4s;
}

.user-widgets .widget-item:hover .icn {
    opacity: .4;
}


/*user nav*/
.user-widget .user-nav {
    position: absolute;
    top: 43px;
    right: 0;
    z-index: 3;
    background-color: #fff;
    padding: 10px 0 10px;
    text-align: left;
    display: none;
    border-top: 2px solid #d9d9d9;

    -webkit-box-shadow: 0 15px 25px 0 rgba(0, 0, 0, .25);
    -moz-box-shadow: 0 15px 25px 0 rgba(0, 0, 0, .25);
    box-shadow: 0 15px 25px 0 rgba(0, 0, 0, .25);
}

.user-widget .user-nav:before {
    content: "";
    width: 100%;
    height: 25px;
    position: absolute;
    left: 0;
    top: -26px;
}

.user-widget .user-nav:after {
    content: "";
    height: 2px;
    width: 33px;
    position: absolute;
    top: -2px;
    right: 0;
    background-color: #000000;
    z-index: 3;
}

.user-widget .user-nav ul li {
    white-space: nowrap;
}

.user-widget .user-nav ul li a {
    display: block;
    position: relative;
    padding: 5px 20px;
}

.user-widget:hover .user-nav {
    display: block;
}

.category-text {
    scroll-margin-top: 200px;
    scroll-snap-margin: 200px;
    margin-top: 30px;
}

.category-text h2,
.category-text h3,
.category-text h4,
.category-text h5,
.category-text h6 {
    margin: 0 0 10px 0;
}


.error-page {
    text-align: center;
    padding: 0 50px;
}

.error-page .error-code {
    font-size: 130px;
    line-height: 90px;
    font-weight: bold;
    margin: 0 0 18px 0;
    color: #333;
}

.error-page .error-text {
    margin: 0 0 20px 0;
    font-size: 14px;
    line-height: 18px;
    line-height: 18px;
}

.p-fix-0 {
    padding: 0;
}


/*header*/
header {
    width: 100%;
    padding: 10px 0 0 0;
    background-color: #fff;
    z-index: 107;
    border-bottom: 1px solid #ccc;
}

header.fixed ~ .page {
    padding-top: 128px !important;
}

header.fixed {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: 12px 0 0 0;
    border-bottom-color: #fff;
    box-shadow: 0 4px 7px 0 rgba(162, 162, 162, 0.1),
    0 0 1px 0 rgba(144, 144, 144, 0.25);
}

header.fixed .logo img {
    height: 46px;
}

header .logo {
    display: table;
    margin: 0 auto;
}

header .logo img {
    height: 55px;
}

header .logo span {
    display: block;
    color: #75797D;
    font-size: 12px;
}

.category-menu .menu {
    text-align: center;
}

.category-menu .menu .menu-item {
    display: inline-block;
    border: none;
}


.main-menu-nav .main-menu {
    text-align: center;
    font-family: Montserrat, sans-serif;
}

.main-menu-nav .main-menu .main-menu-item {
    display: inline-block;
    border: none;
    position: relative;
}

.main-menu-nav .main-menu .main-menu-item .main-menu-link {
    font-size: 15px;
    line-height: 1.5;
    text-transform: uppercase;
    color: #333;
    position: relative;
    padding: 0 10px 15px;
    font-weight: 700;
    display: block;
    -webkit-box-pack: center;
    -webkit-box-orient: vertical;
    font-family: Montserrat, sans-serif;
    letter-spacing: 1.2px;
    margin-top: 12px;
    margin-bottom: -1px;
}
.main-menu-nav .main-menu .main-menu-item .main-menu-link:hover {
    opacity:0.7;
}

.main-menu-nav .main-menu .main-menu-item.active .main-menu-link{
    opacity:0.7;
}
.main-menu-item:hover > .main-sub-menu{
    display:flex;
}

.main-internal-sub-menu li > .main-internal-sub-menu .main-internal-sub-menu-link{
    text-transform: none;
}
.main-sub-menu {
    display: none;
    text-align: left;
    margin-top: 1px;
    background: #ffffff;
    position: fixed;
    padding: 20px 10px;
    width: 100%;
    z-index: 10;
    left: 0;
    box-shadow: 0 4px 7px 0 hsl(0deg 0% 64% / 10%);
}

.main-sub-menu-link {
    width: 100%;
    display: inline-block;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 10px;
    font-size:16px;
    text-indent: 15px;
}
.main-sub-menu-link:hover {
   opacity: 0.7;
}

.main-internal-sub-menu {
    padding-left: 15px;
}

.main-internal-sub-menu-link {
    width: 100%;
    text-transform: uppercase;
    padding-top: 10px;
    display: inline-block;
}
.main-internal-sub-menu-link:hover {
    color:#606060;
}

/*search*/
.search {
    width: 250px;
    -webkit-transition: width .6s;
    transition: width .6s;
    position: absolute;
    left: 10px;
    bottom: 0;
}

.x-search {
    position: absolute;
    top: 11px;
    right: -23px;
    cursor: pointer;
    display: none;
}

.search.expand .x-search {
    display: block;
}

.search.expand .x-search img {
    height: 12px;
}

.search.expand {
    width: 300px;
    margin-right: 35px;
    -webkit-transition: width .6s;
    transition: width .6s;
}

.search.open {
    display: block;
}

.search input[type=text] {
    border: 0;
    padding: 10px 30px 10px 0;
    width: 100%;
    border-bottom: 1px solid #000;
}

.search input::placeholder {
    color: #ababab;
}

.search button {
    border: 0;
    background-color: transparent;
    position: absolute;
    right: 3px;
    top: 10px;
    color: #d9d9d9;
    outline: none;
}

.search button img {
    height: 22px;
}

.search .search-results {
    overflow: auto;
    position: relative;
    padding: 10px 0;
}

.search .search-container {
    position: absolute;
    top: 43px;
    padding: 0 15px;
    z-index: 11;
    background-color: #fff;
    width: 100%;

    -webkit-box-shadow: 0 15px 25px 0 rgba(0, 0, 0, .25);
    -moz-box-shadow: 0 15px 25px 0 rgba(0, 0, 0, .25);
    box-shadow: 0 15px 25px 0 rgba(0, 0, 0, .25);
}

.search .search-results .found {
    font-size: 12px;
    color: #ababab;
}

.search .search-results .result-row {
    background-color: #fff;
    border-top: 0;
    text-align: left;
    border-bottom: 1px solid #d9d9d9;
}

.search .search-results .result-row:last-of-type {
    border-bottom: 0;
}

.search .search-results .result-row .product-title {
    font-size: 12px;
    line-height: 16px;
    font-weight: bold;
    color: #000000;
    display: inline-block;
    font-family: 'Roboto', sans-serif;
}

.search .search-results .result-row .product-title:hover {
    color: #000000;
}

.search .all-results {
    text-align: center;
    display: block;
    font-size: 12px;
    padding: 10px 0;
    border-top: 1px solid #d9d9d9;
    color: #ababab;
}

.search .all-results:hover {
    color: #000000;
}

.search .no-results {
    text-align: center;
    padding: 10px 0;
    border-top: 1px solid #d9d9d9;
    border-bottom: 1px solid #d9d9d9;
    color: #ababab;
}


.login-container {
    position: absolute;
    top: 43px;
    right: 0;
    width: 315px;
    z-index: 11;
    background-color: #fff;
    display: table;
    padding: 25px 15px 20px;
    text-align: left;
    display: none;
    border-top: 2px solid #d9d9d9;

    -webkit-box-shadow: 0 15px 25px 0 rgba(0, 0, 0, .25);
    -moz-box-shadow: 0 15px 25px 0 rgba(0, 0, 0, .25);
    box-shadow: 0 15px 25px 0 rgba(0, 0, 0, .25);
}

.login-container:before {
    content: "";
    width: 100%;
    height: 25px;
    position: absolute;
    left: 0;
    top: -26px;
}

.login-container h3 {
    font-size: 12px;
    line-height: 16px;
    margin: 0 0 10px 0;
    font-weight: bold;
    text-transform: uppercase;
}

.login-container .button {
    display: block;
    width: 100%;
}

.login-container .link-reg,
.login-container .link-pass {
    color: #333;
    display: table;
    font-size: 13px;
    padding-bottom: 0;
    background-image: linear-gradient(transparent calc(100% - 10px), #333 10px);
    background-repeat: no-repeat;
    background-size: 0 1px;
    background-position: 0 100%;
    -webkit-transition: background-size 1s cubic-bezier(.215, .61, .355, 1);
    transition: background-size 1s cubic-bezier(.215, .61, .355, 1);
}

.login-container .link-reg:hover,
.login-container .link-pass:hover {
    background-size: 100% 1px;
}

.login-container .link-reg {
    margin: 0 0 0 auto;
    display: table;
}

.login-container .or, .login .or {
    text-transform: uppercase;
    display: table;
    margin: 10px 0;
    position: relative;
    width: 100%;
    text-align: center;
    opacity: .6;
}

.login-container .or:before, .login .or:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #d9d9d9;
}

.login-container .or span, .login .or span {
    background-color: #fff;
    padding: 0 10px;
    font-size: 12px;
    display: inline-block;
    z-index: 3;
    position: relative;
}

.login-container .btn-fb, .login .btn-fb {
    margin: 0 0 10px 0;
}

.login-container .btn-fb, .login .btn-fb {
    background-color: #4362ad;
    color: #fff;
    font-weight: bold;
}

.login-container .btn-fb:hover, .login .btn-fb:hover {
    background-color: #34539e;
}

.login-container .btn-google, .login .btn-google {
    background-color: #4688f1;
    color: #fff;
}

.login-container .btn-google:hover, .login .btn-google:hover {
    background-color: #2a71e1;
}

.login-container .btn-fb,
.login-container .btn-google,
.login .btn-fb,
.login .btn-google {
    width: 100%;
    display: block;
    text-align: center;
    height: 44px;
    padding: 0 10px;
    font-size: 16px;
    line-height: 42px;
    border: 0;

    -webkit-transition: all .4s;
    transition: all .4s;
}

.user-widget:hover:after {
    display: block;
}

.user-widget:hover .login-container {
    display: block;
}


/*cart*/
.cart-container {
    position: absolute;
    top: 43px;
    right: 0;
    width: 330px;
    z-index: 11;
    background-color: #fff;
    padding: 25px 15px 20px;
    text-align: left;
    display: none;
    border-top: 2px solid #d9d9d9;

    -webkit-box-shadow: 0 15px 25px 0 rgba(0, 0, 0, .25);
    -moz-box-shadow: 0 15px 25px 0 rgba(0, 0, 0, .25);
    box-shadow: 0 15px 25px 0 rgba(0, 0, 0, .25);
}

.cart-container:before {
    content: "";
    width: 100%;
    height: 25px;
    position: absolute;
    left: 0;
    top: -26px;
}

.cart-container .product-title {
    background: transparent;
    padding: 15px 0;
}

.cart-container .button {
    display: block;
}

.comparison-widget {
    position: relative;
}

.comparison-widget .comparison-num {
    position: absolute;
    left: 26px;
    top: -2px;
    background-color: #000000;
    color: #e2e2e2;
    width: 13px;
    height: 13px;
    line-height: 12px;
    font-size: 10px;
    text-align: center;
    border-radius: 100%;
}

.cart-widget {
    padding-right: 0 !important;
}

.cart-widget:after {
    content: "";
    height: 2px;
    width: 100%;
    position: absolute;
    left: 0;
    bottom: -22px;
    background-color: #000000;
    display: none;
    z-index: 11;
}

.cart-widget:hover:after {
    display: block;
}

.cart-widget:hover .cart-container {
    display: block;
}

.cart-container .cart-result {
    max-height: 340px;
    overflow: hidden;
    overflow-y: auto;
    position: relative;
}

.ps > .ps__rail-x,
.ps > .ps__rail-y {
    opacity: 0.6;
}

.cart-container .cart-result .result-row {
    padding: 15px 0;
    margin-right: 15px;
    border-bottom: 1px solid #d9d9d9;
    overflow: hidden;
    position: relative;
}

.cart-container .cart-result .result-row:last-child {
    border: 0 !important;
}

.cart-container .cart-result .product-title {
    font-size: 12px;
    line-height: 17px;
    position: relative;
    display: block;
    margin: 0 0 0 0;
}

.cart-container .cart-result .product-remove {
    position: relative;
    top: -4px;
    font-size: 24px;
    line-height: 1;
    cursor: pointer;
    font-weight: normal;
    color: #dcdcdc;
}

.cart-container .cart-result .product-mill {
    color: #cecece;
}

.cart-container .cart-total {
    font-size: 16px;
    padding: 10px 0;
    color: #000000;
    font-weight: bold;
}

.cart-container .cart-result .product-amount {
    color: #cecece;
}

.cart-container .cart-result .product-amount span {
    font-weight: bold;
    font-size: 12px;
    line-height: 18px;
    color: #000000;
}

.cart-container .cart-result .product-amount small {
    font-size: 14px;
    line-height: 18px;
    color: #000000;
}

.cart-container .empty-cart {
    padding: 20px;
    text-align: center;
    color: #6f6f6f;
}

.cart-container .empty-cart img {
    margin: 0 auto 15px;
    display: block;
    height: 60px;
}

.cart-container .cart-total span {
    float: right;
    color: #000000;
}

.cart-container .btn {
    width: 100%;
    display: block;
    margin: 5px 0 0 0;
}


.cart-empty {
    text-align: center;
}

.cart-empty img {
    height: 150px;
    margin: 0 0 10px 0;
}

nav.hidden {
    display: none;
}

/*menu*/
nav .menu-heading {
    color: #fff;
    background-color: #000000;
    padding: 10px 15px;
    text-transform: uppercase;
    border-radius: 8px 8px 0 0;
    margin: 0 0 10px 0;
    font-size: 16px;
}

nav .menu-heading img {
    margin-right: 15px;
    height: 18px;
}

nav .menu-icon {
    height: 18px;
    position: absolute;
    top: 50%;
    left: 10px;
    margin-top: -10px;
}

nav .menu .menu-item {
    display: block;
    border-bottom: 1px solid #d2d2d2;
}

nav .menu .menu-item.rel {
    position: relative;
}

nav .menu .menu-item.rel > .sub-menu {
    margin: 1px 0 20px 0;
    width: 100%;
    position: fixed;
    background: #fff;
    z-index: 10;
    padding-top: 17px;
    left: 0;
    column-count: 3;
    padding-bottom: 50px;
    box-shadow: 0 4px 7px 0 rgba(162, 162, 162, 0.1);
    display: none;
}

nav .menu .menu-item:hover .sub-menu.show {
    display: block;
}

header.fixed nav .menu .menu-item.rel > .sub-menu {
    margin-top: 0;
}

nav .menu .menu-item .menu-link {
    font-size: 14px;
    line-height: 1.5;
    text-transform: uppercase;
    color: #333;
    position: relative;
    padding: 0 10px 15px 10px;
    font-weight: 600;
    display: block;
    -webkit-box-pack: center;
    -webkit-box-orient: vertical;
    font-family: 'Montserrat', sans-serif;
    letter-spacing: 1.2px;
    margin-top: 12px;
    margin-bottom: -1px;
}

nav .menu .menu-item .menu-link:hover,
nav .menu .menu-item.menu-open > .menu-link {
    color: #333;
    opacity: 0.7;
}

nav .menu .menu-item .sub-menu-item .dropdown-btn {
    position: absolute;
    top: 50%;
    height: 30px;
    width: 30px;
    line-height: 30px;
    font-size: 18px;
    margin-top: -15px;
}

nav .menu .menu-item .dropdown-btn {
    position: absolute;
    top: 50%;
    right: 0;
    height: 40px;
    width: 40px;
    /*background: #f3f3f3;*/
    text-align: center;
    line-height: 40px;
    font-size: 20px;
    margin-top: -20px;
    color: #000000;
}

nav .menu .menu-item.active .menu-link {
    color: #000000;
}

nav .menu .sub-menu .sub-menu-item {
    display: block;
    position: relative;
}

nav .menu .sub-menu-arrow {
    position: absolute;
    right: 16px;
    top: 10px;
}

nav .menu .sub-menu .sub-menu-link {
    position: relative;
    font-size: 13px;
    line-height: 1.5;
    text-align: left;
    margin: 0;
    padding: 0 40px 0 0;
    color: #75797D;
    min-height: 40px;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
}

nav .menu .sub-menu .sub-menu-link.active {
    color: #000000;
}


nav .menu .sub-menu .sub-menu-link::first-letter {
    text-transform: uppercase;
}

nav .menu .menu-item > .sub-menu {
    padding-left: 45px;
}

nav .menu .sub-menu {
    display: none;
}

nav .menu .sub-menu.show {
    display: block !important;
}

nav .menu .menu-item > .sub-menu ul {
    padding-left: 15px !important;
}

nav .menu .menu-item.rel > .sub-menu > .sub-menu-item > a {
    font-family: 'Montserrat', sans-serif;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 12px;
    line-height: 20px;
    font-weight: 400;
}

nav .menu .sub-menu .sub-menu-link:hover,
nav .menu .menu-item.rel > .sub-menu > .sub-menu-item > a:hover {
    color: #333;
}

nav .menu .menu-item .dropdown-btn i {
    -webkit-transition: all .4s;
    transition: all .4s;
}

nav .menu .menu-item.menu-open > a .dropdown-btn i {
    transform: rotate(-180deg);
}

nav .menu .sub-menu-item.menu-open > a .dropdown-btn i {
    transform: rotate(-180deg);
}

.category-menu .menu .menu-item.menu-open .sub-menu {
    display: block;
}

nav .menu-icon {
    display: none;
}

nav .menu .menu-item .dropdown-btn {
    display: none;
}

.category-menu .menu .menu-item.menu-open .sub-menu {
    display: none;
}

.mega-menu {
    position: absolute;
    display: none;
    top: 78px;
    left: 0;
    width: 100%;
    background-color: #fff;
    z-index: 155;
    padding: 20px 20px 20px;
    border-top: 1px solid #d7d7d7;
    box-shadow: 0 4px 3px 0 rgba(162, 162, 162, 0.12),
    0 0 1px 0 rgba(144, 144, 144, 0.25);
}

.mega-menu h5 {
    margin: 0 0 15px 0;
    color: #ababab;
    font-family: 'Roboto', sans-serif;
    letter-spacing: 2px;
    text-transform: uppercase;
    font-size: 12px;
    line-height: 18px;
}

.mega-menu .mega-menu-container {
    margin: 0 auto;
    display: table;
    width: 100%;
    max-width: 1300px;
}

.mega-menu .mega-menu-list li {
    margin: 4px 0;
}

.mega-menu .mega-menu-list li a {
    font-size: 13px;
}

.mega-menu .mega-menu-list > li {
    position: relative;
    padding: 0 0 0 15px;
}

.mega-menu .mega-menu-list.red-dot > li:before {
    content: "";
    background-color: #000000;
    position: absolute;
    top: 6px;
    left: 0;
    width: 10px;
    height: 10px;
    display: block;
    border-radius: 100%;
}

.mega-menu .mega-menu-list.red-dot > li > a {
    color: #000000;
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
}

.mega-menu .mega-menu-list > li > a:hover {
    color: #000000;
}

.mega-menu .mega-menu-list > li > ul {
    margin: 0 0 20px 0;
}

.mega-menu-list {
    margin: 0 0 20px 0;
}

/*intro*/
.intro {
    margin: 0;
}

.intro h2 {
    color: #000000;
    font-size: 44px;
    line-height: 50px;
    font-weight: bold;
    text-transform: uppercase;
    margin: 0 0 10px 0;
}

.intro .carousel h2 {
    text-transform: none;
}

.intro h3 {
    color: #000000;
    font-size: 18px;
    line-height: 24px;
    margin: 0 0 20px 0;
}

.intro .special-price {
    color: #000000;
    padding: 15px;
    text-align: center;
    font-size: 22px;
    line-height: 28px;
    font-weight: bold;
    margin: 0 0 30px 0;
}

.intro .period {
    margin: 10px 0;
    padding: 15px;
    text-align: center;
    color: #75797D;
    font-size: 14px;
    line-height: 20px;
}

.intro .carousel-caption {
    margin: auto;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: left;
    padding: 0 50px 0;
    width: 100%;
    bottom: inherit;
    right: inherit;
}

.intro .carousel-item .title {
    padding-bottom: 10px;
    font-weight: 400;
    font-size: 28px;
    letter-spacing: 1px;
    font-family: 'Montserrat', sans-serif;
    position: relative;
    text-align: center;
}

.intro .carousel-item .text {
    text-transform: uppercase;
    font-weight: 700!important;
    font-size: 14px;
    padding: 15px;
    /*color: #999;*/
    text-align: center;
    margin: 20px 0;
}

.intro .carousel-item .button {
    display: table;
    margin: 0 auto;
}

.carousel-indicators li.active {
    border: 2px solid #000;
    background-color: #000;
}

.carousel-indicators li {
    border: 2px solid #000000;
    background-color: transparent;
    height: 8px;
    width: 8px;
    opacity: 1;
    border-radius: 100%;
    margin: 0 4px
}

.intro .carousel-indicators {
    bottom: 40px;
    left: 50px;
    z-index: 5;
    right: inherit;
    margin: 0;
}


/*benefits*/
.home .benefits {
    border-bottom: 2px solid #efe1d4;
    padding: 60px 0 40px;
}

.home .benefits .benefit {
    position: relative;
}

.home .benefits .benefit p {
    text-align: center;
    font-size: 10px;
    color: #fff;
    position: absolute;
    top: 42%;
    width: 100%;
    padding: 0 10px;
    height: 38px;
    line-height: 38px;
    background-color: #000000;
    border-color: #000000;
    box-shadow: 8px 10px 10px #ddd;
    opacity: 0;
}

.home .benefits .benefit:hover p {
    opacity: 1;
}

.home .benefits .benefit .benefit-img {
    height: 100px;
    margin: 0 0 15px 0;
}

.home .benefits .benefit .benefit-img img {
    height: 100px;
    margin: 0 auto;
    display: block;
}

.home .benefits .benefit h5 {
    color: #000000;
    text-transform: uppercase;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    padding: 0 20px;
    height: 44px;
    font-family: 'Roboto';
}

.home .benefits .benefit .btn {
    padding: 0 10px;
    font-size: 13px;
    text-transform: initial;
    display: block;
    letter-spacing: 0;
    opacity: 0;
    position: absolute;
    top: 42%;
    width: 100%;
    box-shadow: 8px 10px 10px #ddd;

    -webkit-transition: all .4s;
    -moz-transition: all .4s;
    -o-transition: all .4s;
    transition: all .4s;
}

.home .benefits .benefit:hover .btn {
    opacity: 1;
}

.home-cats .cat-link {
    position: relative;
    display: block;
}

.home-cats .cat-action {
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
    padding: 3rem 0;
}

.home-cats img:hover {
    -webkit-filter: brightness(90%);
}

.home-cats .cat-action.inverted {
    -webkit-filter: grayscale(1) invert(1);
    filter: grayscale(1) invert(1);
}

.home-cats .cat-action .cat-name {
    font-family: 'Montserrat', sans-serif;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 26px;
    padding: 15px;
    display: block;
    color: #000;
    letter-spacing: 2px;
}

.gift-package {
    padding: 20px;
    border: 1px solid #e3e2e2;
    background-color: #fff;
}

.gift-card,
.gift {
    padding: 15px 25px;
    border: 1px solid #e3e2e2;
    margin: 0 0 30px 0;
}

.gift-card .switch,
.gift .switch {
    float: right;
}

.gift-card h3,
.gift h3 {
    color: #000000;
    font-size: 18px;
    line-height: 24px;
    font-weight: bold;
    padding: 0 0 5px 0;
}

.gift-card p,
.gift p {
    margin: 0;
}

.gift-card-action h3 .switch,
.gift-action h3 .switch {
    float: right;
}

.gift-action h3 {
    border-bottom: 1px solid #E3E2E2;
    padding: 0 0 15px 0;
    margin: 0 0 25px 0;
    color: #000000;
    font-size: 18px;
    line-height: 24px;
    font-weight: bold;
}

.gift-action .choose-period {
    margin: 20px 0 30px 0;
}

.gift-action .choose-period h4 {
    margin: 0 0 5px 0;
}

.gift-action .choose-period p {
    margin: 0 0 20px 0;
}

.gift-card-action .card-text {
    margin: 20px 0 0 0;
}

.gift-card-action .card-text .card-lab {
    padding: 15px 20px;
    border-top: 1px solid #e3e2e2;
    border-left: 1px solid #e3e2e2;
    border-right: 1px solid #e3e2e2;
}

.gift-card-action .card-text .card-lab img {
    margin-right: 15px;
}

.gift-card-action .card-text .card-lab h5 {
    font-size: 16px;
    line-height: 22px;
    font-family: 'Roboto', sans-serif;
}

.gift-card-action .card-text .card-lab p {
    font-size: 14px;
    margin: 0;
}

.gift-card-action .card-text .form-control {
    width: 100%;
}

.gift-card-action h3 {
    color: #000000;
    font-size: 18px;
    line-height: 24px;
    font-weight: bold;
    padding: 0 0 15px 0;
}


.mystery-box-order .config {
    padding: 25px;
    background-color: #FBFAF9;
    margin: 0 0 30px 0;
}

.mystery-box-order .config h3 {
    border-bottom: 1px solid #E3E2E2;
    padding: 0 0 15px 0;
    margin: 0 0 25px 0;
    color: #000000;
    font-size: 18px;
    line-height: 24px;
    font-weight: bold;
}

.mystery-box-order .choose-date h4,
.mystery-box-order .choose-period h4 {
    font-size: 16px;
    line-height: 22px;
    color: #000000;
    font-weight: bold;
    margin: 0 0 15px 0;
}

.mystery-box-order .choose-period ul {
    border: 1px solid #000000;
    font-size: 0;
    display: table;
}

.mystery-box-order .choose-period ul li {
    width: 2%;
    font-size: 13px;
    display: table-cell;
    border-right: 1px solid #000000;
    text-align: center;
    height: 42px;
    line-height: 42px;
    cursor: pointer;

    -webkit-transition: all .4s;
    -moz-transition: all .4s;
    -o-transition: all .4s;
    transition: all .4s;
}

.mystery-box-order .choose-period ul li:last-of-type {
    border-right: 0;

}

.mystery-box-order .choose-period ul li:hover {
    background-color: #f3ecf5;
}

.mystery-box-order .choose-period ul li.selected {
    background-color: #000000;
    color: #fff;
}

.mystery-box-order .choose-date,
.mystery-box-order .choose-period {
    background-color: #fff;
    padding: 20px;

    box-shadow: 0px 0px 10px 7px rgba(103, 103, 103, .05);
}

.mystery-box-order .choose-date .btn {
    width: 100%;
}


.cart {
    padding: 45px 0 100px;
}

.cart h1 {
    margin: 0 0 40px 0;

}

.cart .total-action .button {
    display: block;
}

.cart .back-to-shop {
    margin: 30px 0 0 0;
    color: #000000;
    display: inline-block;
}

.back-to-shop {
    font-size: 12px;
}

.back-to-shop + .cart-headings {
    margin-top: 20px;
}

.cart-benefits {
    padding: 30px 0 0;
}

.guarante-cart {
    text-align: center;
    margin: 20px 0 0 0;
}

.guarante-cart img {
    margin-right: 5px;
}

.cart-list .product-name {
    text-transform: uppercase;
    margin: 0 0 5px 0;
    display: inline-block;
    font-size: 14px;
    line-height: 20px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
}

.cart-list .product-name:hover {
    color: #000;
}

.cart-list .product-old-price {
    color: #ddd;
}

.cart-list .product-img img {
    margin: 0 auto;
    display: block;
}

.remove-product:focus {
    outline: none;
}

.remove-product img {
    width: 16px;
}

.remove-product {
    outline: none;
    border: 0;
    background-color: transparent;
    margin-left: 10px;
    opacity: .5;
    /*position: absolute;*/
    /*top: 0;*/
    /*right: 15px;*/
    float: right;
    font-size: 18px;

    width: 16px;
    -webkit-transition: all .4s;
    -moz-transition: all .4s;
    -o-transition: all .4s;
    transition: all .4s;
}

.remove-product:hover {
    opacity: 1;
}

.cart-list .product-desc {
    font-size: 12px;
    line-height: 18px;
    color: #afafaf;
}

.cart-list .product-desc div {
    margin-bottom: 2px;
}

.cart-delivery-hint {
    background-color: #f1eced;
    padding: 11px 15px;
    margin: 25px 0 20px 0;
}

.cart-headings {
    padding: 0 0 15px 0;
    font-size: 12px;
    text-transform: uppercase;
    border-bottom: 1px solid #E3E2E2;
}

.cart-list .cart-row {
    padding: 20px 0;
    border-bottom: 1px solid #E3E2E2;
}

.cart-list .cart-row .package-discounts .badge {
    font-size: 13px;
    margin: 5px 0;
}

.add-new-address {
    margin: 20px auto;
}

.promo-code {
    padding: 25px;
    border: 1px solid #e3e2e2;
    margin: 0 0 30px 0;
}

.promo-code .promo-code-input {
    margin: 0 0 20px 0;
}

.promo-code .btn {
    display: block;
    padding: 0 20px;
}


.checkout {

    padding: 45px 0 100px 0;
}

.checkout .payment-method {
    margin: 0 0 15px 0;
}


.total {
    padding: 25px 0 0;
    border-bottom: 0;
    background: #F5F5F5;
}

.total.with-border {
    border-bottom: 1px solid #e3e2e2;
}

.cart .total-action {
    background: #F5F5F5;
    border: none;
}

.total h3 {
    margin: 0 25px 0 25px;
    color: #000000;
    text-transform: uppercase;
    font-size: 20px;
    padding-bottom: 20px;
    display: block;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    line-height: 1.4;
    border-bottom: 1px solid #d1d1d1;
}

.total .remove-product {
    float: none;
    margin: 0 auto;
    display: table;
}

.total .product-row {
    margin: 0 25px;
    border-bottom: 1px solid #e3e2e2;
    padding: 20px 0;
}

.total .product-row:first-of-type {
    padding-top: 0;
}

.total .product-row:last-of-type {
    border: 0;
}

.total .product-row .product-name {
    margin: 0;
    font-size: 12px;
}

.total .product-row .product-desc {
    color: #000000;
    font-size: 12px;
    line-height: 16px;
}

.total .product-row .product-old-price {
    text-decoration: line-through;
    color: #ddd;
}

.total .product-row .product-price {
    color: #414141;
    text-align: right;
}

.cart .sum {
    padding-top: 0;
}

.total-action .sum {
    margin: 0 0 0;
    padding: 0 0 25px 0;
}

.sum {
    padding: 10px 0 25px 0;
    margin: 0 25px 0;
}

.sum ul li {
    padding: 15px 0;
    border-bottom: 1px solid #d1d1d1;
    display: table;
    width: 100%;
}

.sum ul li span {
    float: right;
    width: 50%;
    text-align: right;
    color: #000000;
}

.sum ul li:last-of-type {
    font-size: 18px;
    border-bottom: 0;
    padding-bottom: 0;
    color: #75797D;
    font-weight: bold;
}

.sum ul li:last-of-type span {
    color: #000000;
}

.total .total-hint {
    padding: 20px 25px 20px 60px;
    position: relative;
    border-top: 1px solid #e3e2e2;
    background-color: #FBFAF9;
    margin: 0 0 0 0;
}

.total .total-hint p {
    color: #000000;
}

.total .total-hint p:last-of-type {
    margin: 0;
}

.total .total-hint img {
    position: absolute;
    top: 25px;
    left: 20px;
}

.total-action {
    padding: 25px;
    border-top: 1px solid #d1d1d1;
    background-color: #F5F5F5;
}

.total-action .checkbox {
    margin: 0 0 20px 0;
}

.total-action .btn {
    display: block;
    width: 100%;
}

.sticky-top {
    top: 140px;
    z-index: 3;
}

.order-payment h3 {
    border-bottom: 1px solid #E3E2E2;
    padding: 0 0 15px 0;
    margin: 0 0 25px 0;
    color: #000000;
    font-size: 18px;
    line-height: 24px;
    font-weight: bold;
    text-transform: uppercase;
}

.order-payment .payment-method {
    position: relative;
}

.order-payment .payment-method input[type=radio] {
    position: absolute;
    top: 50%;
    left: 18px;
    margin-top: -6.4px;
}

.order-payment .payment-method input[type=radio]:checked ~ label {
    background-color: #fff;
    border-color: #000000;
}

.order-payment .payment-method label {
    border: 1px solid #E3E2E2;
    padding: 20px 10px 20px 45px;
    width: 100%;
    cursor: pointer;
}

.order-payment .payment-method label .payment-icon {
    margin-right: 10px;
    height: 30px;
    width: 40px;
    display: inline-flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
}

.order-payment .payment-method label .payment-icon img {
    position: relative;
    display: block;
    height: 100%;
    position: relative;
    top: 5px;
}

.order-payment .payment-method label .payment-name {
    color: #000000;
    font-size: 16px;
    line-height: 18px;
    margin: 0 0 7px 0;
    font-weight: bold;
    display: inline-block;
}

.order-payment .payment-method label .payment-desc {
    color: #75797D;
    font-size: 12px;
    line-height: 18px;
}


.order-delivery {
    margin: 0 0 30px 0;
}

.order-delivery h3 {
    border-bottom: 1px solid #E3E2E2;
    padding: 0 0 15px 0;
    margin: 0 0 25px 0;
    color: #000000;
    font-size: 18px;
    line-height: 24px;
    font-weight: bold;
    text-transform: uppercase;
    font-family: 'Montserrat', sans-serif;
}

.order-delivery .delivery-method {
    position: relative;
}

.order-delivery .delivery-method input[type=radio] {
    position: absolute;
    top: 50%;
    left: 18px;
    margin-top: -6.5px;
}

.order-delivery .delivery-method input[type=radio]:checked ~ label {
    background-color: #fff;
    border-color: #000000;
}

.order-delivery .delivery-method label {
    border: 1px solid #E3E2E2;
    padding: 20px 20px 20px 45px;
    width: 100%;
    cursor: pointer;
    min-height: 110px;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    min-height: 120px;
}

.order-delivery .delivery-method label .delivery-name {
    font-family: 'Roboto', sans-serif;
    color: #000000;
    font-size: 16px;
    line-height: 18px;
    font-weight: bold;
}

.order-delivery .delivery-method label .delivery-desc {
    color: #75797D;
    font-size: 12px;
    line-height: 18px;
    margin: 5px 0 0 0;
}


.order-delivery h4 {
    margin: 0 0 15px 0;
    color: #000000;
    font-size: 16px;
    line-height: 22px;
    font-weight: bold;
    font-family: 'Montserrat', sans-serif;
    text-transform: uppercase;
}

.order-delivery .delivery-types {
    margin: 15px 0 0 0;
}

.order-delivery .delivery-type {
    position: relative;
    margin: 0 0 20px 0;
}

.order-delivery .delivery-type input[type=radio] {
    position: absolute;
    top: 50%;
    left: 18px;
    margin-top: -6.5px;
}

.order-delivery .delivery-type input[type=radio]:checked ~ label {
    background-color: #fff;
    border-color: #000000;
}

.order-delivery .delivery-type label {
    border: 1px solid #E3E2E2;
    padding: 20px 10px 20px 45px;
    width: 100%;
    margin: 0;
    cursor: pointer;
}

.order-delivery .delivery-type label .delivery-type-icon {
    position: absolute;
    top: 50%;
    right: 20px;
    margin-top: -9px;
}

.order-delivery .delivery-type label .delivery-type-icon img {
    max-height: 18px;
    display: block;
}

.profile-content .order-delivery .delivery-type label .delivery-type-icon img {
    display: none;
}

.order-delivery .delivery-type label .delivery-type-name {
    font-family: 'Roboto', sans-serif;
    color: #000000;
    font-size: 15px;
    line-height: 17px;
    font-weight: 500;
}

.order-delivery .delivery-type label .delivery-type-desc {
    color: #000000;
    font-size: 12px;
    line-height: 16px;
}

.order-delivery .delivery-type label .delivery-type-text {
    color: #75797d;
    font-size: 12px;
    line-height: 18px;
    margin: 5px 0 0 0;
}


.order-info {
    margin: 0 0 30px 0;
}

.order-info.payment-info {
    padding: 0;
}

.order-info h3 {
    padding: 0 0 15px 0;
    margin: 0 0 25px 0;

}

.order-info label {
    margin: 0 0 4px 0;
}

label.required span {
    color: #000000;
}

.order-info .form-msg {
    color: #000000;
    font-size: 12px;
    margin: 5px 0 0 0;
}


.products {
    padding-bottom: 100px;
}

.products .hero-area {
    padding: 50px 0;
}

.products .hero-area h1 {
    font-size: 50px;
    line-height: 60px;
    margin: 0 0 15px 0;
    color: #fff;
}

.products .hero-area p {
    color: #fff;
}

.products .hero-area a {
    color: #fff;
    text-decoration: underline;
}

.products .custom-box,
.home .custom-box {
    width: 100%;
    height: 100%;
    display: flex;
}
.home .custom-box {
    justify-content: center;
    align-items: end;
}

.products .custom-box .text-box,
.home .custom-box .text-box {
    padding: 40px;
}
.home .custom-box .text-box{
    bottom: auto;
    width: auto;
}

.products .custom-box.inverted .text-box,
.home .custom-box.inverted .text-box {
    -webkit-filter: grayscale(1) invert(1);
    filter: grayscale(1) invert(1);
}

.products .custom-box .title,
.products .custom-box .text,
.home .custom-box .title,
.home .custom-box .text {
    font-family: 'Montserrat', sans-serif;
    text-transform: uppercase;
    font-weight: 400;
    letter-spacing: 2px;
    margin-bottom: 15px;
}

.products .custom-box .title,
.home .custom-box .title {

    border-bottom: 1px solid #000;
    padding-bottom: 15px;
}
.home .carousel-item .custom-box .text-box{
    padding: 40px 70px;
}
.home .carousel-item .custom-box .text-box.transperant{
    background: rgba(255, 255, 255, 0.7);
}
@media(max-width: 991px){
    .home .carousel-item .custom-box .text-box{
        padding: 10px 0;
    }
}
.products .custom-box:hover img,
.home .custom-box:hover img {
    -webkit-filter: brightness(90%);
}

#wo-breadcrumbs {
    padding: 15px 0;
}

.sort h3 {
    font-family: 'Roboto', sans-serif;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    margin: 0 0 20px 0;
    position: relative;
}

.sort.active h3:after {
    content: "\f106";
    font-family: "FontAwesome";
    top: 0;
    right: 0;
    position: absolute;
    font-size: 16px;
}

.sort h3:after {
    content: "\f107";
    font-family: "FontAwesome";
    top: 0;
    right: 0;
    position: absolute;
    font-size: 16px;
}

.sort.active .sort-result {
    display: block;
}

.sort .sort-result {
    display: none;
}


.countries h3 {
    font-family: 'Roboto', sans-serif;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    margin: 8px 0 20px 0;
    position: relative;
}

.countries.active h3:after {
    content: "\f106";
    font-family: "FontAwesome";
    top: 0;
    right: 0;
    position: absolute;
    font-size: 16px;
}

.countries h3:after {
    content: "\f107";
    font-family: "FontAwesome";
    top: 0;
    right: 0;
    position: absolute;
    font-size: 16px;
}

.countries.active .countries-result {
    display: block;
}

.countries .countries-result {
    display: none;
}

.countries {
    margin: 0 0 30px 0;
}

.countries .view-all {
    text-align: center;
    padding: 11px 0;
}

.countries .view-all a {
    color: #afafaf;
    cursor: pointer;
    text-decoration: underline;
}

.countries .view-all a:hover {
    color: #000000;
}

.countries .clear-filter {
    padding: 11px 0;
    text-align: left;
}

.countries .clear-filter button img {
    height: 20px;
    position: relative;
    top: -1px;
    margin-right: 1px;
}

.countries .clear-filter button {
    color: #afafaf;
    cursor: pointer;
    outline: none;
    background-color: transparent;
    border: 0;
    transition: all .4s;
}

.countries .clear-filter button:hover {
    color: #000000;
}

.countries h3 {
    font-family: 'Roboto', sans-serif;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    margin: 0 0 20px 0;
}

.countries .country {
    padding: 10px;
    border: 1px solid #e3e2e2;
    margin: 0 0 20px 0;
    text-align: center;
    color: #000000;
    cursor: pointer;

    -webkit-transition: all .4s;
    -moz-transition: all .4s;
    -o-transition: all .4s;
    transition: all .4s;
}

.countries .country.active {
    background-color: #000000;
    color: #fff;
    border-color: #000000;
}

.countries .country:hover {
    border-color: #000000;
}


/* product */
.product .product-img {
    position: relative;
}

.product .product-func {
    margin: 6px 0 0 0;
    text-align: center;
}

.product .product-func button, .product .product-func a {
    background-color: transparent;
    border: 0;
    padding: 10px 0;
    outline: none;
    transition: all .4s;
    color: #75797D;
    font-size: 12px;
    margin: 0 20px;
}

.product .product-func button:hover {
    opacity: .4;
}

.product .product-func button img {
    height: 13px;
    margin: 0 2px 0 0;
}

.product .assortment {
    padding-bottom: 100px;
}

.product .product-info {
    padding: 20px 0;
}

.product .product-info .buy {
    display: block;
    width: 100%;
    font-weight: 500;
    font-size: 16px;
}

.product h1 {
    margin: 0 0 7px 0;
    line-height: 26px;
}

.product .product-sku {
    display: inline-block;
    margin-right: 30px;
    font-weight: 500;
}

.product .product-sku span {
    font-weight: lighter;
}

.product .product-src {
    display: inline-block;
    font-weight: 500;
}

.product .product-src a {
    font-weight: lighter;
}

.product .product-stock.in-stock {
    color: #179767;
    margin: 0 0 10px 0;
}

.product .product-stock.not-in-stock {
    color: #000000;
    margin: 0 0 10px 0;
}

.product .product-stock.in-stock i, .product .product-stock.not-in-stock i {
    margin-right: 5px;
}

.product .product-rate {
    position: relative;
    display: table;
}

.product .product-rate .rate-tooltip {
    position: absolute;
    top: 40px;
    background-color: #fff;
    padding: 20px;
    border: 1px solid #343a40;
    z-index: 3;
    display: none;
    width: 320px;

    box-shadow: 0 7px 10px 0 rgba(162, 162, 162, 0.12),
    0 0 1px 0 rgba(144, 144, 144, 0.25);
}

.product .product-rate .rate-tooltip:after {
    content: "";
    position: absolute;
    top: -23px;
    left: 0;
    height: 23px;
    width: 100%;
}

.product .product-rate .rate-tooltip:before {
    content: "";
    transform: rotate(45deg);
    width: 20px;
    height: 20px;
    position: absolute;
    top: -11px;
    left: 93px;
    background-color: #fff;
    border-left: 1px solid #343a40;
    border-top: 1px solid #343a40;
}

.product .product-rate:hover .rate-tooltip {
    display: block;
}

.product .product-rate .rate-tooltip .btn {
    margin: 15px auto 0;
    display: table;
    text-decoration: none;
}

.product .product-rate .rate-tooltip .rate-list ul {
    padding: 0 30px;
}

.product .product-rate .rate-list ul li {
    margin-bottom: 10px;
}

.product .product-rate .rate-list ul li i {
    color: #000000;
    font-size: 16px;
}

.product .product-rate .rate-list ul li .val {
    font-family: 'Roboto', sans-serif;
    display: inline-block;
}

.product .product-rate .rate-list ul li .rate-progress-bar {
    display: inline-block;
    background-color: #3c4046;
    height: 5px;
    position: absolute;
}

.product .product-rate .rate-list ul li .rate-progress {
    position: relative;
    background-color: #CAC7C6;
    width: 100%;
    height: 5px;
}

.product .product-rate > ul {
    display: inline-block;
}

.product .product-rate > ul li {
    display: inline-block;
}

.product .product-rate > ul li i {
    color: #000000;
}

.product .product-rate .rate {
    display: inline-block;
    margin: 0 0 0 10px;
}

.product .product-rate a {
    display: inline-block;
    font-size: 12px;
    text-decoration: underline;
}

.product .product-desc {
    margin: 20px 0;
}

.product .product-desc p {
    color: #75797D;
}

.product .product-long-desc {
    margin: 15px 0 0 0;
}

.product .product-long-desc .nav {
    border-bottom: 1px solid #AAAAAA;
}

.product .product-long-desc .nav a {
    display: inline-block;
    padding: 12px 20px;
    position: relative;
    text-transform: uppercase;
    color: #75797D;
}

.product .product-long-desc .nav a.active {
    color: #000000;
    font-weight: 500;
}

.product .product-long-desc .nav a:hover {
    color: #000000;
}

.product .product-long-desc .nav a:before {
    content: "";
    position: absolute;
    left: 0;
    bottom: -2px;
    width: 0;
    background-color: #333;
    height: 3px;

    -webkit-transition: all .4s;
    transition: all .4s;
}

.product .product-long-desc .nav a.active:before {
    width: 100%;
}

.product .product-long-desc .technical-data {
    width: 100%;
}

.product .product-long-desc .technical-data thead {
    font-size: 16px;
    line-height: 22px;
    background-color: #000000;
}

.product .product-long-desc .technical-data thead tr td {
    padding: 12px 20px;
    color: #fff;
}

.product .product-long-desc .technical-data thead tr td:nth-child(2) {
    border-left: 1px solid #42899e;
    text-align: right;
}

.product .product-long-desc .technical-data tbody tr:nth-child(even) td:nth-child(2) {
    background-color: #F2F2F2;
    border-left: 1px solid #F2F2F2;
}

.product .product-long-desc .technical-data tbody tr td:nth-child(2) {
    background-color: #E8EDEF;
    border-left: 1px solid #E8EDEF;
}

.product .product-long-desc .technical-data tbody tr td:nth-child(2) {
    text-align: right;
}

.product .product-long-desc .technical-data tbody tr td {
    padding: 12px 20px;
}

.product .product-long-desc .technical-data tbody tr {
    background-color: #F9F9F9;
}

.product .product-long-desc .technical-data tbody tr:nth-child(even) {
    background-color: #F2F6F8;
}

.variant-error {
    font-size: 12px;
    color: #fff;
    background-color: #EA4335;
    padding: 1px 12px;
    display: table;
}

.variant-error i {
    margin-right: 3px;
    font-size: 12px;
}


.product .product-variants {
    margin: 10px 0;
}

/*.product .product-variants.alerts-border {*/
/*    border: 1px #ff0000 solid;*/
/*    animation: blink 1s;*/
/*    animation-iteration-count: 1;*/
/*}*/

@keyframes blink {
    50% {
        border-color: #FBFAF9;
    }
}

.variants {
    margin: 0 0 20px 0;
}

.product-variants .lab {
    font-weight: 500;;
}

.product .product-variants .product-variant {
    display: inline-block;
    margin-top: 5px;
    margin-right: 10px;
    padding: 4px 10px;
    background-color: #fff;
    border: 1px solid #000000;
    cursor: pointer;
    position: relative;

    -webkit-transition: all .4s;
    transition: all .4s;
}
.product .product-variants .product-variant.gift-variant {
    min-width: 70px;
    text-align: center;
}

.product .product-variants .product-variant.variant-rounded {
    width: 40px;
    height: 40px;
    padding: 0;
}

.product .product-variants .product-variant.variant-rounded.selected:not(.withX) {
    /*// background-color: #000000;*/
    border-color: #000;
    color: #fff;
    opacity: 1;
}

.product .product-variants .product-variant.variant-rounded.selected:not(.withX):after {
    content: '✔';
    position: absolute;
    top: 0;
    right: 0;
    font-size: 20px;
    line-height: 40px;
    width: 40px;
    text-align: center;
}

.product .product-variants .product-variant.selected {
    background-color: #000000;
    border-color: #000000;
    color: #fff;
    opacity: 1;
}

.product .product-variants .product-variant.unavailable {
    opacity: .3!important;
}

.product .product-variants .product-variant.withX{
    opacity: 0.5;
}
.product .product-variants .product-variant.variant-rounded.unavailable:after,
.product .product-variants .product-variant.variant-rounded.withX:after
{
    content: 'x' !important;
    position: absolute;
    top: 0;
    right: 0;
    font-size: 19px;
    line-height: 38px;
    width: 38px;
    text-align: center;
    color: darkred;
}

.product .product-variants .product-variant:hover {
    border-color: #000000;
}

.product .product-variants .product-variant img {
    height: 22px;
    margin-right: 10px;
}

.product .product-variants .product-variant .variant-discount {
    position: absolute;
    top: -14px;
    right: -18px;
    background-color: #E81E25;
    width: 35px;
    height: 35px;
    text-align: center;
    line-height: 35px;
    color: #fff;
    border-radius: 100%;
    font-size: 10px;
}

.product .product-prices {
    margin: 0 0 15px 0;
}

.product .product-prices .new-price {
    font-size: 24px;
    line-height: 24px;
    font-weight: bold;
    color: #4E5358;
    display: inline-block;
    margin-right: 10px;
}

.product .product-prices .old-price {
    display: inline-block;
    text-decoration: line-through;
    color: #bbb;
}

.product .product-prices .price-measure {
    font-weight: bold;
    color: #333;
    font-size: 13px;
    margin: 4px 0 0 0;
}

.product .product-prices .price-single {

    font-style: italic;
    font-size: 12px;
}

.product .product-delivery-hint {
    margin: 20px 0;
}

.product .product-delivery-hint h5 {
    font-size: 14px;
    line-height: 20px;
}

.product-quantity {
    position: relative;
    border: 1px solid #e3e2e2;
}

.product .product-features {
    padding: 70px 0;
}

.product .product-features.gray {
    background-color: #FBFAF9;
}

.product .product-features h4 {
    font-size: 18px;
    line-height: 24px;
    color: #000000;
    margin: 0 0 15px 0;
}

.product .temperature img {
    margin: 0 0 8px 0;
}

.product .temperature span {
    display: block;
}

.product .region-info span {
    display: block;
    color: #c1c1c1;
}

.product .region-info span a {
    display: block;
    color: #c1c1c1;
}

.product .region-info .flag {
    display: block;
    margin: 5px 0 5px 0;
}

.product .product-sort {
    padding: 0 0 6px 0;
    margin-bottom: 50px;
    border-bottom: 1px solid #e3e2e2;
}

.product .product-aromas ul li {
    display: inline-block;
    text-align: center;
    line-height: 16px;
}

.product .product-aromas ul li img {
    display: block;
    margin: 0 auto 10px;
}

.product .suitable-foods ul li {
    display: inline-block;
    text-align: center;
    line-height: 18px;
}

.product .suitable-foods ul li img {
    display: block;
    margin: 0 auto 10px;
}

.product .product-profile ul li {
    padding: 12px 30px 12px 0;
    border-bottom: 1px solid #e3e2e2;
}

.product .product-profile ul li .ingredient {
    display: inline-block;
    width: 95px;
}

.product .product-profile ul li .dot.empty {
    background-color: #DBD8DB;
}

.product .product-profile ul li .dot {
    background-color: #E81E25;
    width: 18px;
    height: 18px;
    border-radius: 100%;
    margin: 0 6px;
    display: inline-block;
}

.review-modal button.close {
    color: #fff;
    opacity: 1;
}

.review-modal button.close:focus {
    border: 0;
    outline: none;
}

.add-review .modal-body {
    padding: 0;
}

.add-review .review-info {
    background-color: #FBFAF9;
    border-left: 1px solid #DCDBDA;
    padding: 30px 40px;
}

.add-review .product-rate {
    padding: 30px 0;
    font-size: 20px;
    font-weight: bold;
    color: #222;
}

.add-review .product-rate > ul {
    display: inline-block;
    margin-left: 15px;
    position: relative;
    top: 5px;
}

.add-review .product-rate > ul li {
    display: inline-block;
    margin-right: 7px;
}

.add-review .product-rate > .product-stars {
    position: relative;
    display: inline-block;
    margin-left: 15px;
    top: 8px;
}

.add-review .product-rate > .product-stars input {
    position: absolute;
    z-index: -1;
    opacity: 0;
    padding: 0;
    margin: 0;
    bottom: 0;
}

.add-review .product-rate > .product-stars .fa {
    color: #333;
    font-size: 34px;
    margin-right: 7px;
    font-weight: normal;
    float: right;
    margin-bottom: 0;
    cursor: pointer;
}


.star-rating:hover:before,
.star-rating:hover ~ .star-rating:before,
.star-rating-input:checked ~ .star-rating:before {
    content: "\f005";
}

.add-review .form-hint {
    color: #b9b9b9;
    font-size: 14px;
}

.add-review .form-group {
    margin-bottom: 20px;
}

.add-review label, .add-review legend {
    margin-bottom: 5px;
    font-size: 16px;
    font-weight: bold;
    color: #222;
}

.add-review button {
    margin: 60px auto 0;
    display: table;
}

.add-review .review-img {
    margin: 30px auto 0;
    display: block;
    padding-left: 30px;
}

.add-review .lab-no {
    float: left;
}

.add-review .lab-yes {
    float: right;
}

.add-review .eval {
    margin: 0 0 5px 0;
    display: table;
    width: 100%;
}

.add-review .eval li {
    float: left;
    width: 10%;
}

.add-review .eval li label {
    text-align: center;
    padding: 10px 0;
    background-color: #F2F2F2;
    border-top: 1px solid #C9C9C9;
    border-bottom: 1px solid #C9C9C9;
    border-left: 1px solid #C9C9C9;
    cursor: pointer;
    display: block;
    font-size: 14px;

    -webkit-transition: all .4s;
    -moz-transition: all .4s;
    -o-transition: all .4s;
    transition: all .4s;
}

.add-review .eval li label:hover {
    opacity: .7;
    color: #000000;
}

.add-review .eval li input {
    display: none;
}

.add-review .eval li input:checked + label {
    background-color: #000000;
    border-top-color: #000000;
    border-bottom-color: #000000;
    color: #fff;
}

.add-review .eval li input:checked + label:hover {
    opacity: 1;
}

.add-review .eval li:last-of-type label {
    border-right: 1px solid #C9C9C9;
}


.add-review .rec-fr {
    display: table;
    width: 100%;
}

.add-review .rec-fr li {
    float: left;
}

.add-review .rec-fr li label {
    text-align: center;
    padding: 10px 25px;
    background-color: #F2F2F2;
    border-top: 1px solid #C9C9C9;
    border-bottom: 1px solid #C9C9C9;
    border-left: 1px solid #C9C9C9;
    cursor: pointer;
    display: block;
    font-size: 14px;

    -webkit-transition: all .4s;
    -moz-transition: all .4s;
    -o-transition: all .4s;
    transition: all .4s;
}

.add-review .rec-fr li label:hover {
    opacity: .7;
    color: #000000;
}

.add-review .rec-fr li input {
    position: absolute;
    z-index: -1;
    opacity: 0;
    padding: 0;
    margin: 0;
    bottom: 0;
}

.add-review .rec-fr li input:checked + label {
    background-color: #000000;
    border-top-color: #000000;
    border-bottom-color: #000000;
    color: #fff;
}

.add-review .rec-fr li input:checked + label:hover {
    opacity: 1;
}

.add-review .rec-fr li:last-of-type label {
    border-right: 1px solid #C9C9C9;
}


.modal-content {
    border: 0;
}

.modal-header {
    border-radius: 0;
}

.add-review .modal-header {
    background-color: #000000;
}

.add-review .modal-header h5 {
    color: #fff;
    text-align: center;
    display: block;
    width: 100%;
}

.reviews-holder {
    background-color: #f5f5f5;
    padding: 20px 30px;
}

.reviews-sticky {
    top: 30px;
}

.comments {
    padding: 40px 0;
    margin: 45px 0;
}

.comments .btn {
    margin: 0 0 20px;
    display: table;
}

.comments .rating .rate {
    font-size: 36px;
    font-weight: bold;
}

.comments .rating .rate .fa {
    font-size: 22px;
}

.comments .rating .count {
    font-size: 12px;
    color: #bbb;
}

.comments .rate-list .btn {
    margin: 0 0 29px auto;
    display: table;
}

.comments .rate-list ul li {
    margin-bottom: 10px;
}

.comments .rate-list ul li i {
    color: #000000;
    font-size: 16px;
}

.comments .rate-list ul li .val {
    font-family: 'Roboto', sans-serif;
    display: inline-block;
}

.comments .rate-list ul li .rate-progress-bar {
    display: inline-block;
    background-color: #3c4046;
    height: 5px;
    position: absolute;
}

.comments .rate-list ul li .rate-progress {
    position: relative;
    background-color: #CAC7C6;
    width: 100%;
    height: 5px;
}

.comments .comment-row {
    padding: 35px 0 35px 0px;
    border-bottom: 1px solid #e3e2e2;
}

.comments .comment-row:first-child {
    padding-top: 0;
}

.comments .comment-row:last-child {
    border: 0;
}

.comments .comment-row .wine-recommended {
    margin: 20px 0 0 0;
}

.comments .comment-row .wine-recommended.yes {
    color: #179767;
}

.comments .comment-row .wine-recommended.no {
    color: #B70F14;
}

.comments .comment-row .wine-recommended i {
    margin-right: 2px;
}

.comments .comment-row .reviews-count {
    color: #c5c5c5;
    font-size: 12px;
}

.comments .comment-row .comment-author {
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    font-weight: bold;
    color: #000000;
}

.comments .comment-row .comment-heading {
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    margin: 0 0 10px 0;
    font-weight: bold;
    color: #414141;
}

.comments .comment-row .comment-rate {
    margin: 0 0 10px 0;
    float: right;
}

.comments .comment-row .comment-rate ul {
    display: inline-block;
}

.comments .comment-row .comment-rate ul li {
    display: inline-block;
}

.comments .comment-row .comment-rate ul li i {
    color: #000000;
}

.comments .comment-row .comment-rate span {
    color: #c5c5c5;
    margin-left: 15px;
    font-size: 12px;
}

.comments .more {
    color: #000000;
    padding: 35px 0 0;
    margin: 0 auto;
    display: table;
    text-decoration: underline;
}

.comments .more:hover {
    color: #000000;
}

.comments .empty-comments {
    text-align: center;
}

.comments .empty-comments img {
    height: 60px;
    margin: 0 0 15px 0;
}

.comments .empty-comments h3 {
    margin: 0 0 3px 0;
}

.comments .empty-comments .btn {
    margin: 0 auto;
    display: table;
}


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.product-quantity input[type=number] {
    width: 100%;
    text-align: center;
    border: 0;
    height: 42px;
    -webkit-appearance: none;
    -moz-appearance: textfield;
}

.product-quantity button {
    background-color: transparent;
    border: 0;
    position: absolute;
    top: 0;
    height: 42px;
    width: 35px;
    outline: none;
}

.product-quantity .minus {
    left: 0;
}

.product-quantity .plus {
    right: 0;
}


/*product list*/
.products-list {
    padding: 25px 0 40px 0;
}

.products-list .heading {
    text-align: center;
    margin-bottom: 20px;
}

.home .products-list {
    border-top: 1px solid #D6D6D6;
}

.products-list .product-item {
    text-align: center;
    position: relative;
    margin: 0 0 20px 0;
    display: inline-block;
}

.products-list .owl-special-products .product-item {
    margin: 0;
}

.products-list .product-item .product-name a {
    text-transform: uppercase;
    font-weight: 400;
    font-family: 'Montserrat', sans-serif;
    padding-bottom: 1px;
}


.products-list .product-item:hover {
    box-shadow: 0 0 5px 0 rgb(178 178 178 / 60%);
}


.products-list .product-item .product-prices {
    margin: 10px 0 9px;
}

.products-list .product-item:hover .product-name {
    color: #000000;
}

.products-list .product-item .product-name {
    font-size: 14px;
    line-height: 22px;
    position: relative;
    color: #000000;
    padding: 0 15px;

    -webkit-transition: all .4s;
    -moz-transition: all .4s;
    -o-transition: all .4s;
    transition: all .4s;
}

.products-list .product-item .product-attributes{
    display: flex;
    justify-content: center;
    gap: 5px;
    margin-bottom: 10px;
}
.products-list .product-item .product-attributes .single-feature-option{
    width: 15px;
    height: 15px;
    box-shadow: 1px 1px 5px 1px #dfdfdf;
    cursor: pointer;
}

.products-list .product-item:hover .product-desc {
    opacity: 1;
    bottom: 5px;
}

.products-list .product-item .product-desc {
    font-size: 12px;
    position: absolute;
    bottom: -30px;
    opacity: 0;
    width: 100%;
    padding: 40px 20px 10px 20px;
    text-align: left;
    color: #000000;

    -webkit-transition: all .8s;
    transition: all .8s;
}

.products-list .product-item:hover .product-img:before {
    /*opacity: 1;*/
}

.products-list .product-item .product-img:before {
    content: "";
    background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;

    -webkit-transition: all .4s;
    transition: all .4s;
}

.products-list .product-item .product-prices .old-price {
    color: #bdbdbd;
    text-decoration: line-through;
}

.products-list .product-item .product-prices .price,
.products-list .product-item .product-prices .old-price {
    display: inline-block;
    margin: 0 5px;
    font-size: 16px;
}

.products-list .product-item .product-prices .price {
    color: #868686;
    font-size: 13px;
    font-weight: 400;
    font-family: 'Montserrat', sans-serif;
}

.products-list .product-item {
    overflow: hidden;
    position: relative;
    -webkit-transition: all .4s;
    -moz-transition: all .4s;
    -o-transition: all .4s;
    transition: all .4s;
}

.products-list .product-item .product-img {
    overflow: hidden;
    position: relative;
    display: block;
    -webkit-transition: all .4s;
    -moz-transition: all .4s;
    -o-transition: all .4s;
    transition: all .4s;
}

.products-list .product-item .product-info {
    position: relative;
    background-color: #fff;
    padding: 15px 0 15px 0;
}

.products-list .product-item .product-info .colors span {
    color: #868686;
    font-size: 12px;
}

.products-list .product-item .product-action {
    opacity: 0;
    position: absolute;
    top: 85%;
    margin-top: -10px;
    width: 100%;
    -webkit-transition: all .4s;
    transition: all .4s;
}

.products-list .product-item:hover .product-action {
    opacity: 1;
    margin-top: -22.5px;
}

.products-list .product-item .product-action ul {
    margin: 0 auto;
    display: table;
}

.products-list .product-item .product-action ul li {
    display: inline-block;
    margin: 0 8px;
}

.products-list .product-item .product-action ul li button {
    width: 45px;
    height: 45px;
    background-color: #000000;
    text-align: center;
    padding: 10px;
    border: 0;

    -webkit-transition: all .4s;
    -moz-transition: all .4s;
    -o-transition: all .4s;
    transition: all .4s;
}

.products-list .product-item .product-action ul li button:focus,
.products-list .product-item .product-action ul li button:active {
    outline: none !important;
    box-shadow: none !important;
}

.products-list .product-item .product-action ul li button img {
    height: 26px;
    padding: 0;
}

.products-list .product-item .product-action ul li button:hover {
    background-color: #3b3b3b;
}

.products-list.product-recently .signature img {
    height: 20px;
}

.products-list .product-item:hover .add-fav {
    opacity: 1;
}

.owl-products-no-clone {
    margin: 0 -15px;
}

.owl-products-no-clone .owl-stage-outer .product-item,
.owl-products .owl-stage-outer .product-item {
    margin: 20px 15px;
}

.add-fav {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 3;
    opacity: 0;

    -webkit-transition: all .4s;
    -moz-transition: all .4s;
    -o-transition: all .4s;
    transition: all .4s;
}

.add-fav img {
    height: 15px;
}

.product-labels {
    position: absolute;
    top: 20px;
    right: 15px;
    z-index: 2;
}

.product-labels .product-label {
    color: #fff;
    background-color: #000000;
    font-size: 11px;
    text-transform: uppercase;
    text-align: center;
    padding: 4px 10px;
}

.product-labels .product-label.label-new {
    background-color: #000000;
}

.product-labels .product-label.label-discount {
    background-color: #e81e25;
}

.product-labels .product-label.label-discount.discount-fixed {
    width: auto;
    max-width: 100px;
    padding: 2px 5px;
    height: auto;
    line-height: inherit;
}

.product-labels .product-label.label-score {
    border: 2px solid #ddd;
    background-color: #fff;
    color: #333;
    font-size: 13px;
    line-height: 36px;
}

.product-labels li {
    margin-bottom: 5px;
}

.signature {
    margin: 10px 0 0 0;
}

.signature img {
    height: 28px;
    width: inherit !important;
}


/*product recently*/
.similar-products {
    padding-top: 25px;
    background: #eaebea;
    padding-bottom: 100px;
}


/*product recently*/
.product-recently {
    padding-left: 70px;
    padding-right: 70px;
    padding-top: 100px;
    padding-bottom: 25px;
}


/*new offers*/
.new-offers {
    padding-left: 70px;
    padding-right: 70px;
    padding-top: 100px;
    padding-bottom: 25px;
}

.new-offers.btn {
    padding: 0 10px;
}


/*special offers*/
.special-offers {
    background-color: #FBFAF9;
    padding-bottom: 0;
}

.special-offers.products-list .product-item .product-info {
    background-color: #FBFAF9;
}


/*assortment*/
.assortment .assortment-item .assortment-img img {
    width: 100%;
}

.assortment .assortment-item {
    text-align: center;
    min-height: 100%;
    background-color: #fbfaf9;
    position: relative;
}

.assortment .assortment-item .btn {
    position: absolute;
    bottom: 25px;
    min-width: 60%;
    left: 50%;
    margin-left: -30%;
}

.assortment .assortment-item .assortment-info {
    background-color: #fbfaf9;
    padding: 20px 30px 70px;
}


.assortment .assortment-item h3 {
    position: relative;
    font-size: 20px;
    line-height: 26px;
    font-weight: bold;
    margin: 0 0 30px 0;
    color: #000000;
    min-height: 52px;
}

.assortment .assortment-item h3:after {
    content: "";
    width: 30px;
    height: 1px;
    background-color: #bbb;
    position: absolute;
    bottom: -18px;
    left: 50%;
    margin-left: -15px;
}


/*offers*/
.offers {
    padding: 0 0 40px 0;
}

.offers .main-offer {
    margin: 0 0 30px 0;
    position: relative;
    overflow: hidden;
}

.offers .main-offer:before {
    content: "";
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 0;
    background-image: linear-gradient(rgb(0 0 0 / 0%), rgb(0 0 0 / 40%));
    pointer-events: none;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
}

.offers .main-offer:hover:before {
    height: 100%;
    background-image: linear-gradient(rgb(0 0 0 / 0%), rgb(0 0 0 / 70%));
}

.offers .main-offer-text {
    position: absolute;
    left: 0;
    bottom: 18%;
    background-color: rgba(105, 29, 94, .8);
    height: auto;
    padding: 20px 0;
    width: 100%;
    padding-left: 15%;
    z-index: 3;

    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
}

.offers .main-offer-text h3 {
    font-family: 'Roboto', sans-serif;
    color: #fff;
    font-size: 28px;
    letter-spacing: 13px;
    text-transform: uppercase;
}

.offers .main-offer,
.offers .small-offer {
    position: relative;
}

.offers .main-offer .btn,
.offers .small-offer .btn {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -23px;
    margin-left: -80px;
    text-align: center;
    opacity: 0;
}

.offers .main-offer:hover .btn,
.offers .small-offer:hover .btn {
    opacity: 1;
}

.offers .small-offer:before {
    content: "";
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 0;
    background-image: linear-gradient(rgb(0 0 0 / 0%), rgb(0 0 0 / 40%));
    pointer-events: none;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
}

.offers .small-offer:hover:before {
    height: 100%;
    background-image: linear-gradient(rgb(0 0 0 / 0%), rgb(0 0 0 / 70%));
}


/*newsletter*/
.newsletter {
    background-color: #fff;
    position: relative;
    padding: 30px 0 40px 0;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
}

.form-check {
    position: initial;
    display: initial;
    padding: initial;
}

.newsletter .newsletter-form {
    text-align: center;
    max-width: 500px;
    margin: 0 auto;
    display: table;
}

.newsletter .newsletter-form .button {
    height: 40px;
    line-height: 40px;
}

.newsletter .container {
    position: relative;
    z-index: 3;
}

.newsletter .title {
    display: block;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin-bottom: 0;
}


.newsletter .form-control {
    border: 1px solid #868686;
    border-radius: 0;
    color: #333;
    height: 40px;
}

.newsletter label {
    color: #fff;
}

.newsletter .btn {
    height: 44px;
}

.newsletter .hint {
    color: #000;
    margin: 0 0 10px 0;
    font-size: 14px;
    text-align: center;
}

.newsletter .newsletter-social {
    text-align: center;
    height: 100%;
}

.newsletter .newsletter-social ul {
    margin: 17px 0 0 0;
    width: 100%;
    text-align: center;
}

.newsletter .newsletter-social li {
    display: inline-block;
    margin: 0 6px;
}

.newsletter .newsletter-social li a {
    color: #333;
    font-size: 24px;
    line-height: 40px;
    height: 26px;
    width: 26px;
    text-align: center;
    display: block;
}

.newsletter .newsletter-social li a:hover {
    opacity: 0.7;
}

.newsletter .newsletter-form .form-group {
    display: none;
}


.newsletter-block {
    background: #181818;
}

.newsletter-block h4 {
    font-family: 'Montserrat', sans-serif;
}

/* EVENT */
.event .event-content {
    background-color: #fff;
    margin-top: -250px;
}

.event .event-short {
    background-color: #FBFAF9;
    height: 100%;
}

.event .event-desc {
    word-break: break-all;
    padding: 40px 20px 50px 20px;
    border-left: 1px solid #bbb;
    border-right: 1px solid #bbb;
}

.event .event-desc .event-meta p {
    margin: 0;
}

.event .event-desc .event-meta .event-price small {
    font-size: 16px;
    line-height: 22px;
}

.event .event-desc .event-meta .event-price {
    font-size: 40px;
    line-height: 40px;
    font-weight: bold;
    color: #222;
    font-family: 'Roboto', sans-serif;
}

.event .event-desc .event-meta {
    padding: 15px;
    background-color: #FBFAF9;
    margin: 0 0 20px 0;
}

.event .event-desc .event-meta span {
    font-size: 12px;
    line-height: 18px;
    margin: 0 0 5px 0;
    text-transform: uppercase;
    font-weight: bold;
    color: #222;
    display: block;
}

.event .event-desc ul li {
    margin: 0 0 10px 0;
}

.event .event-desc h3 {
    color: #000000;
    margin: 0 0 10px 0;
}

.event .event-desc h1 {
    font-size: 50px;
    line-height: 60px;
    margin: 0 0 30px 0;
}

.event .event-info {
    padding: 5px 30px;
    font-size: 16px;
    line-height: 22px;
    font-weight: bold;
}

.event .event-date {
    padding: 30px 30px 25px 30px;
    margin: 0 0 20px 0;
    position: relative;
    color: #333;
    border-bottom: 1px solid #bbb;
}

.event .event-date span {
    font-size: 70px;
    line-height: 70px;
    font-weight: bold;
    display: inline-block;
    margin-right: 15px;
}

.event .event-date small {
    display: inline-block;
}

.event .event-action-container {
}

.event .event-action {
    margin: 60px 0 0 0;
    padding: 0 20px 0 20px;
}

.event .event-action {
    text-align: center;
}

.event .event-action .btn {
    display: block;
    width: 100%;
    margin: 0 0 15px 0;
}

/* blog view */
.blog-view h1 {
    text-align: center;
}

.blog-view .blog-content {
    margin: 30px 0 0 0;
}

.blog-view .blog-main-img {
    margin: 0 20px 0 0;
}

.blog-view .meta {
    text-align: center;
    margin: 0 0 30px 0;
}

.blog-view .meta .date {
    text-transform: uppercase;
    font-size: 14px;
    line-height: 20px;
    margin: 0 0 5px 0;
    position: relative;
}

.blog-view .meta .date:before {
    content: "";
    position: absolute;
    top: -15px;
    left: 50%;
    margin-left: -15px;
    width: 30px;
    height: 3px;
    background-color: #000000;
}

.blog-view .meta .author {
    font-size: 16px;
    line-height: 22px;
    color: #000000;
}

.blog-similar.blog-list.space {
    padding: 100px 0;
}

.blog-similar.blog-list .blog-item {
    margin: 0;
}

.blog-similar.blog-list .blog-item h3 {
    min-height: 72px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 0;
    padding-right: 0;
}

.blog-similar.blog-list .blog-item h3 a {
    font-size: 16px;
    line-height: 24px;
}

.blog-view .blog-content figure.image_resized {
    margin: auto;
    margin-bottom: 10px;
}

.blog-view .blog-content figure.image-style-align-left {
    float: left;
    margin-right: 10px;
}

.blog-view .blog-content figure.image-style-align-right {
    float: right;
    margin-left: 10px;
}

.navigation-thumbs {
    padding: 20px 60px 0;
}

.navigation-thumbs.owl-carousel .owl-nav {
    position: absolute;
    top: 50%;
    margin-top: -25px;
    width: 100%;
}

.navigation-thumbs.owl-carousel .owl-nav .owl-prev {
    position: absolute;
    left: 10px;
}

.navigation-thumbs.owl-carousel .owl-nav .owl-next {
    position: absolute;
    right: 10px;
}

.navigation-thumbs.owl-carousel .owl-nav button {
    background-color: transparent !important;
    outline: none;
    font-size: 50px;
    line-height: 50px;
    height: 50px;
}

/*blog*/
.home .blog-list .blog-item {
    margin: 0 0 0 0;
}

.blog-list .heading {
    text-align: center;
}

.blog-list .blog-item {
    margin: 0 0 50px 0;
    box-shadow: 0 5px 10px #d8d8d8;
    padding: 15px 0 15px 0;
}

.blog-list .blog-item-img {
    display: block;
    position: relative;
    overflow: hidden;
}

.blog-list .blog-item-img img {
    transition: all 0.5s;
}

.blog-list .blog-item-img:hover img {
    transform: scale(1.1);
}

.blog-list .blog-item-img:hover a {
    opacity: 1;
}

.blog-list .blog-item-img:before {
    content: "";
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 0%;
    background-image: linear-gradient(rgb(0 0 0 / 0%), rgb(0 0 0 / 10%));

    -webkit-transition: all .4s;
    -moz-transition: all .4s;
    -o-transition: all .4s;
    transition: all .4s;
}

.blog-list .blog-item-img:hover:before {
    height: 100%;
    background-image: linear-gradient(rgb(0 0 0 / 0%), rgb(0 0 0 / 40%));
}

.blog-list .blog-item h3 {
    min-height: 60px;
    padding: 0 20px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-top: 5px;
}

.blog-list .blog-item h3 a {
    line-height: 28px;
    font-weight: 500;
    display: inline-block;
    color: #000000;
}

.blog-list .blog-item h3 a:hover {
    color: #000000;
}

.blog-list .blog-item .blog-date {
    color: #75797D;
    padding: 0 20px;
}

.blog-list .blog-item .blog-short {
    color: #303842;
    padding: 10px 20px 10px;
    line-height: 24px;
}

.blog-list .view-all {
    margin: 50px auto 0;
    display: table;
}

.blog-list .hero-area {
    padding: 110px 0;
}

.blog-list .hero-area h1 {
    font-size: 50px;
    line-height: 60px;
    margin: 0 0 15px 0;
    color: #fff;
    text-align: center;
}

.blog-list .blog-filter {
    margin: 0 0 50px 0;
}

.blog-list .blog-filter li {
    display: inline-block;
    margin: 0 5px;
}

.blog-list .blog-filter li a {
    padding: 10px;
    border: 1px solid #e3e2e2;
    margin: 0 0 20px 0;
    text-align: center;
    color: #000000;
    cursor: pointer;
    display: block;

    -webkit-transition: all .4s;
    -moz-transition: all .4s;
    -o-transition: all .4s;
    transition: all .4s;
}

.blog-list .blog-filter li.active a {
    background-color: #000000;
    color: #fff;
    border-color: #000000;
}

.blog-list .blog-filter li a:hover {
    border-color: #000000;
}

.blog-list .blog-filter li:first-of-type {
    margin-left: 0;
}

.blog-list .blog-filter li:last-of-type {
    margin-right: 0;
}


.event .page-info {
    margin-bottom: 0;
}


.events-list {
    padding-bottom: 100px;
}

.events-list .heading {
    text-align: left;
    margin-bottom: 25px;
}

.events-list .cstm-pagination {
    margin-top: 50px;
}

.event-row {
    position: relative;
    margin: 0 0 30px 0;
    overflow: hidden;
    display: block;
}

/*.event-row:last-of-type {
    margin: 0 0 0 0;
}*/

.event-row:before {
    content: "";
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 60%;
    background-image: linear-gradient(rgb(0 0 0 / 0%), rgb(0 0 0 / 90%));
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
}

.event-row:hover:before {
    height: 100%;
    background-image: linear-gradient(rgb(0 0 0 / 0%), rgb(0 0 0 / 100%));
}

.event-row .event-label {
    background-color: #E81E25;
    color: #fff;
    width: 90px;
    height: 90px;
    text-align: center;
    line-height: 88px;
    border-radius: 100%;
    position: absolute;
    top: 30px;
    left: 30px;
    z-index: 3;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-size: 12px;
}

.event-row .event-info {
    position: absolute;
    bottom: 0;
    padding: 15px 10px;
}

.event-row .event-info .event-date {
    padding: 0 20px;
    margin: 20px 30px 20px 0;
    color: #fff;
    position: relative;
}

.event-row .event-info .event-capacity {
    color: #fff;
    text-align: center;
}

.event-row .event-info .event-desc:before {
    content: "";
    height: 100%;
    position: absolute;
    top: 0;
    left: -32px;
    background-color: #ffffff7a;
    width: 1px;
}

.event-row .event-info .event-date span {
    font-size: 70px;
    line-height: 40px;
    font-weight: bold;
    color: #fff;
    display: inline-block;
    margin-right: 15px;
}

.event-row .event-info .event-date small {
    display: inline-block;
}

.event-row .event-info .event-desc {
    padding: 0 20px;
    margin: 20px 0;
    position: relative;
}

.event-row .event-info .event-desc h3 {
    color: #fff;
    margin: 0 0 10px 0;
    font-size: 24px;
    line-height: 30px;
    font-weight: bold;
}

.event-row .event-info .event-desc p {
    color: #fff;
    word-break: break-all;
}

.events-list .hero-area {
    padding: 110px 0;
}

.events-list .hero-area h1 {
    font-size: 50px;
    line-height: 60px;
    margin: 0 0 15px 0;
    color: #fff;
    text-align: center;
}

.events-filter h4 {
    text-transform: uppercase;
    font-family: 'Roboto', sans-serif;
    margin: 0 0 25px 0;
}

.event-checkout .total-action {
    border-top: 0;
}

.event-checkout .cart-delivery-hint img {
    height: 18px;
    margin-right: 4px;
    position: relative;
    top: -2px;
}

.event-checkout .cart-delivery-hint {
    margin-top: 0;
    background-color: #f1e5f1;
}

.event-checkout .sum {
    padding: 10px 0 10px 0;
    border-bottom: 1px solid #e3e2e2;
}


.mystery-box .hero {
    position: relative;
    overflow: hidden;
}

.mystery-box .hero-text {
    position: absolute;
    left: 0;
    bottom: 18%;
    background-color: rgba(105, 29, 94, .8);
    height: auto;
    padding: 20px 0;
    width: 100%;
    padding-left: 15%;
    z-index: 3;

    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
}

.mystery-box .hero h3 {
    font-family: 'Roboto', sans-serif;
    color: #fff;
    font-size: 28px;
    letter-spacing: 13px;
    text-transform: uppercase;
}

.mystery-box .video .heading {
    text-align: left;
    text-transform: inherit;
    margin-bottom: 30px;
    color: #000000;
}

.mystery-box .video p {
    margin-bottom: 30px;
}

.mystery-box .video iframe {
    width: 100%;
    height: 450px
}

.back {
    margin: 15px 0 0 0;
    display: inline-block;
}

.back i {
    margin-right: 5px;
}


.order-auth {
    background-repeat: repeat-y;
    background-position: bottom left;
    background-size: 250px;
}

.order-auth-login .forgot-password-link,
.login .forgot-password-link {
    float: right;
    text-decoration: underline;
    color: #969595;

    transition: all .4s;
}

.order-auth-login .forgot-password-link:hover,
.login .forgot-password-link:hover {
    color: #333;
    text-decoration: none;
}

.icon-writing {
    top: 12px;
}

.icon-mail {
    top: 15px;
}

.icon-password {
    top: 11px;
}

.order-auth-login button,
.login button {
    display: block;
    width: 100%;
}

.login .new-reg {
    text-align: center;
    margin: 40px 0 0 0;
}

.login .new-reg span {
    display: block;
}

.login .new-reg a {
    display: inline-block;
    text-decoration: underline;
    color: #969595;
    margin: 2px 0 0 0;
}

.login .new-reg a:hover {
    text-decoration: none;
    color: #333
}

.order-auth-login {
    padding: 60px 30px;
}

.order-auth-reg {
    padding: 60px 30px;
    background-color: #FBFAF9;
}

.order-auth-reg .or {
    text-align: center;
    margin: 11px 0;
}

.order-auth-reg p {
    text-align: center;
    margin-bottom: 11px;
}

.order-auth-reg .btn {
    display: block;
}

.order-auth .heading {
    margin: 0 0 45px 0;
    font-size: 22px;
    line-height: 26px;
}

.order-auth .heading-icon {
    margin: 0 auto 20px;
    display: block;
}

.page-info {
    padding: 0;
}

.page-info h1 {
    margin: 17px 0 30px 0;
    text-align: center;
}

.page-info ol li {
    display: inline-block;
    margin: 0 10px;
    position: relative;
    color: #75797D;
}

.page-info ol li:first-of-type {
    margin-left: 0;
}

.page-info ol li a {
    text-decoration: none;
    color: #333;
}

.page-info ol li::after {
    content: "›";
    position: absolute;
    top: 0;
    right: -12px;
}

.page-info ol li:last-of-type:after {
    display: none;
}

/* filters */
.sidebar .active-filters h4 {
    font-family: 'Roboto', sans-serif;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    margin: 0 0 20px 0;
    position: relative;
}

.sidebar h3 {
    font-family: 'Roboto', sans-serif;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    margin: 0 0 20px 0;
    position: relative;
}

.sidebar.active h3:after {
    content: "\f106";
    font-family: "FontAwesome";
    top: 0;
    right: 0;
    position: absolute;
    font-size: 16px;
}

.sidebar h3:after {
    content: "\f107";
    font-family: "FontAwesome";
    top: 0;
    right: 0;
    position: absolute;
    font-size: 16px;
}

.active-filters {
    border-bottom: 1px solid #e3e2e2;
    padding: 20px 0;
    text-transform: uppercase;
}

.active-filters h4 {
    display: inline-block;
    font-size: 14px;
    text-transform: initial;
    margin: 0 20px 0 0;
    color: #75797D;
}

.active-filters ul {
    display: inline-block;
}

.active-filters ul li {
    margin: 0 20px 0 0;
    display: inline-block;
}

.active-filters .filter-type {
    display: inline-block;
    font-weight: 500;
}

.active-filters .filter-value {
    padding: 8px 55px 8px 15px;
    text-align: center;
    color: #2C2E30;
    display: inline-block;
    margin: 0 0 0 10px;
    background-color: #F2F6F8;
    position: relative;
}

.active-filters .filter-value span {
    cursor: pointer;
    background-color: #E8EAEB;
    width: 40px;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    text-align: center;
}

.active-filters .filter-value span img {
    height: 11px;
    margin-left: 0;
    position: relative;
    top: 8px;
}

.active-filters .clear-all {
    text-transform: initial;
}

.active-filters .clear-all a {
    color: #75797D;
}

.active-filters .clear-all a:hover {
    color: #000000;
}

.active-filters .clear-all a:before {
    content: "";
    height: 12px;
    width: 12px;
    background: url('../../images/icons/remove.svg') no-repeat center center;
    background-size: 12px;
    display: inline-block;
    margin-right: 6px;
    position: relative;
    top: 1px;
}

.active-filters .filter-value span .icn {
    display: inline-block;
}

.active-filters .filter-value span:hover .icn {
    display: none;
}

.active-filters .filter-value span .icn-hover {
    display: none;
}

.active-filters .filter-value span:hover .icn-hover {
    display: inline-block;
}

.active-filters .filter-value:hover {
    border-color: #000000;
}

.sidebar.active .widgets {
    display: block;
}

.widgets .widget:first-of-type {
    /*padding-top: 0;*/
}

.widgets .widget .widget-box {
    display: none;
}

.widgets .widget.widget-active .widget-box {
    display: block;
}

.widgets .widget.widget-active h4 {
    text-decoration: underline;
}

.widgets .widget {
    padding: 10px 0;
}

.widgets .widget h4 {
    font-size: 14px;
    line-height: 20px;
    padding-right: 20px;
    letter-spacing: 1px;
    font-weight: bold;
    text-transform: uppercase;
    color: #000000;
    font-family: 'Montserrat', sans-serif;
    position: relative;
    cursor: pointer;
}

.widgets .widget h5 {
    font-size: 14px;
    line-height: 20px;
    padding: 3px 0;
    color: #222;
    font-family: 'Roboto', sans-serif;
    position: relative;
    cursor: pointer;
}

.widgets .widget .category-icon {
    font-size: 16px;
    margin-right: 5px;
}

.widgets .widget.widget-active h4:after {
    content: "\f106";
    font-family: "FontAwesome";
    top: 0;
    right: 0;
    position: absolute;
    font-size: 16px;
}

.widgets .widget h4:after {
    content: "\f107";
    font-family: "FontAwesome";
    top: 0;
    right: 0;
    position: absolute;
    font-size: 16px;
    font-weight: normal;
}

.widgets .widget .aside-dropdown > li {
    position: relative;
    padding: 0 0 0 15px;
}

.widgets .widget .aside-dropdown > li.dd-menu ul {
    display: none;
}

.widgets .widget .aside-dropdown > li.dd-menu:after {
    content: "\f105";
    font-family: "FontAwesome";
    top: 0;
    left: 0;
    position: absolute;
    font-size: 16px;
}

.widgets .widget .aside-dropdown > li.dd-simple-menu:after {
    content: "\f105";
    font-family: "FontAwesome";
    top: 0;
    left: 0;
    position: absolute;
    font-size: 16px;
}

.widgets .widget .aside-dropdown > li.dd-menu-active ul {
    display: block;
}

.widgets .widget .aside-dropdown > li.dd-menu-active:after {
    content: "\f107";
    font-family: "FontAwesome";
    top: 0;
    left: 0;
    position: absolute;
}

.widgets .widget .aside-dropdown > li:last-of-type {
    border: 0;
}

.widgets .widget .aside-dropdown > li > a {
    padding: 3px 0;
    display: block;
    font-size: 14px;
    line-height: 20px;
}

.widgets .widget .aside-dropdown > li > a:hover {
    color: #000000;
}

.widgets .widget .aside-dropdown > li > ul {
    padding: 0 0 15px 0;
}

.widgets .widget .aside-dropdown > li > ul > li > a {
    padding: 4px 15px 4px 10px;
    display: block;
    line-height: 18px;
    color: #afafaf;
}

.widgets .widget .aside-dropdown > li > ul > li > a:hover {
    color: #000000;
}


.widgets .widget .sort-search {
    position: absolute;
    width: 280px;
    margin-top: 5px;
    z-index: 4;
}
.sort-search + .filter-list {
    margin-top: 50px!important;
}
.widgets .widget .sort-search img {
    position: absolute;
    top: 10px;
    left: 10px;
    width: 18px;
}

.widgets .widget .sort-search .form-control {
    font-size: 12px;
    padding-left: 35px;
}


.widgets .widget .filter-list {
    position: absolute;
    background-color: #fff;
    z-index: 4;
    padding: 10px 15px;
    box-shadow: 0 2px 7px rgba(0, 0, 0, .1);
    width: 280px;
    margin-top: 15px;
}

@media (min-width: 767px) {
    .filter-wrapper {
        display: flex;
        flex-flow: row;
        gap: 1em;
    }

    .filter-list.limit, .filter-list.sort {
        width: 100% !important;
        left: 0;
    }

    .filter-list.sort {
        min-width: 230px;
    }
}

.widgets .widget .filter-list .filter-item {
    padding: 0 0 0 28px;
    position: relative;
    margin: 10px 0;
}

.widgets .widget .filter-list .filter-item a {
    color: #2c2e30;
}

.widgets .widget .filter-list .filter-item a:hover {
    color: #333;
}

.widgets .widget .filter-list .filter-item.selected a {
    color: #333;
}

.widgets .widget .filter-list .filter-item.selected:after {
    content: "";
    background: url(../../images/icons/check.svg) center center no-repeat;
    background-size: 24px;
    position: absolute;
    top: 0px;
    left: 0;
    width: 19px;
    height: 20px;
}

.widgets .widget .filter-list .filter-item:before {
    content: "";
    width: 18px;
    height: 18px;
    border: 1px solid #e3e2e2;
    display: block;
    position: absolute;
    top: 1px;
    left: 0;
}

.widgets .widget .filter-list .filter-item.selected:before {
    border-color: #333;
}

.widgets .clear-all {
    margin: 20px 0;
}

.widgets .clear-all a {
    color: #afafaf;
    font-size: 12px;
    text-decoration: underline;
}

.widgets .clear-all a:hover {
    color: #000000;
}


/*footer*/
footer {
    background-color: #fff;
    padding: 30px 0;
}

footer .logo {
    margin: -15px 0 35px 0;
    display: block;
}

footer .logo img {
    height: 60px;

    -webkit-transition: all .2s;
    transition: all .2s;
}

footer .logo span {
    display: block;
    color: #fff;
    font-size: 12px;
    opacity: .6;
    margin: 3px 0 0 0;
    font-weight: lighter;
}

footer .about-company p {
    color: #fff;
    margin: 0;
}

footer h3 {
    font-size: 16px;
    line-height: 22px;
    text-transform: uppercase;
    font-weight: 300;
    margin: 0 0 20px 0;
    font-family: 'Montserrat', sans-serif;
    letter-spacing: 1.2px;
}

footer .footer-menu li {
    display: block;
    margin: 10px 0;
}

footer .footer-menu li a {

    padding-bottom: 3px;
    background-image: linear-gradient(transparent calc(100% - 10px), #333 10px);
    background-repeat: no-repeat;
    background-size: 0 1px;
    background-position: 0 100%;
    transition: background-size 1s cubic-bezier(.215, .61, .355, 1);
}

footer .footer-menu li a:hover {
    background-size: 100% 1px;
    color: #333;
}

footer .footer-delivery a {

    color: #B1B2B2;

    padding-bottom: 3px;
    background-image: linear-gradient(transparent calc(100% - 10px), #333 10px);
    background-repeat: no-repeat;
    background-size: 0 1px;
    background-position: 0 100%;
    transition: background-size 1s cubic-bezier(.215, .61, .355, 1);
}

footer .footer-delivery a:hover {

    background-size: 100% 1px;
    color: #fff;
}


footer .footer-delivery p {
    color: #B1B2B2;
}

footer .footer-delivery img {
    margin-right: 20px;
}


footer .footer-contacts li {
    display: block;
    margin: 8px 0;
    padding: 0 0 0 27px;
    position: relative;
}

footer .footer-contacts li i {
    margin-right: 7px;
    color: #fff;
    width: 15px;
    position: absolute;
    top: 4px;
    left: 0;
    text-align: center;
}

footer .footer-contacts li a {
    color: #B1B2B2;
    padding-bottom: 3px;
    background-image: linear-gradient(transparent calc(100% - 10px), #fff 10px);
    background-repeat: no-repeat;
    background-size: 0 1px;
    background-position: 0 100%;
    transition: background-size 1s cubic-bezier(.215, .61, .355, 1);
}

footer .footer-contacts li a:hover {
    background-size: 100% 1px;
    color: #fff;
}

footer .copyright {
    padding: 35px 0 0 0;
    margin: 0;
    text-align: center;
    font-size: 12px;
    color: #9ba0a4;
}


/*form components*/
.radio,
.checkbox {
    padding: 0 0 0 26px;
    position: relative;
}

.radio input[type="radio"],
.checkbox input[type="checkbox"] {
    opacity: 0;
    z-index: 1;
    cursor: pointer;
    position: absolute;
}

.checkbox label::before {
    content: "";
    position: absolute;
    width: 17px;
    height: 17px;
    left: 0;
    border: 1px solid #cccccc;
    background-color: #fff;
}

.checkbox label::after {
    content: "";
    position: absolute;
    width: 16px;
    height: 16px;
    left: 3px;
    top: 0;
    font-size: 12px;
    color: #555555;
}

.checkbox input[type="checkbox"]:checked + label::after {
    font-family: "FontAwesome";
    content: "\f00c";
}

.radio label::before {
    content: "";
    position: absolute;
    width: 17px;
    height: 17px;
    left: 0;

    border: 1px solid #cccccc;
    border-radius: 50%;
    background-color: #fff;

    -webkit-transition: border 0.15s ease-in-out;
    -o-transition: border 0.15s ease-in-out;
    transition: border 0.15s ease-in-out;
}

.radio label::after {
    content: "";
    position: absolute;
    width: 11px;
    height: 11px;
    left: 3px;
    top: 3px;
    border-radius: 50%;
    background-color: #555555;

    -webkit-transform: scale(0, 0);
    -ms-transform: scale(0, 0);
    -o-transform: scale(0, 0);
    transform: scale(0, 0);

    -webkit-transition: -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
    -moz-transition: -moz-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
    -o-transition: -o-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
    transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
}

.radio input[type="radio"] {
    opacity: 0;
    z-index: 1;
    cursor: pointer;
}

.radio input[type="radio"]:checked + label::after {
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
}


/*owl*/
.owl-carousel .owl-nav {
    position: absolute;
    top: -34px;
    right: 0;
    margin: 0;
}

.owl-carousel .owl-nav button {
    width: 32px;
    height: 32px;
    background-color: #000000 !important;
    border-radius: 6px !important;
}

.owl-carousel .owl-nav button .fa {
    font-size: 22px;
    line-height: 22px;
    height: 22px;
    display: block;
    color: #fff;
}

.owl-theme .owl-nav [class*=owl-]:hover {
    background: none;
    color: #869791;
}

.owl-theme .owl-nav [class*=owl-] {
    outline: none;
}

.user-widget .user-nav,
.sub-lang {
    z-index: 125;
}

.scroll-top img {
    transform: rotate(180deg);
    width: 17px;
    margin-top: 13px;
}

.scroll-top {
    width: 40px;
    height: 40px;
    display: block;
    border: 1px solid #ccc;
    border-radius: 2px;
    color: #333;
    background: #ccc;
    text-align: center;
    position: fixed;
    right: 35px;
    bottom: 70px;
    z-index: 105;
    opacity: 0;

    -webkit-transition: all .4s;
    transition: all .4s;
}

.scroll-top.shw {
    opacity: 1;
    bottom: 100px;
}

.scroll-top:hover,
.scroll-top:active,
.scroll-top:focus {
    background-color: #A8A9A8;
}


.switch label {
    cursor: pointer;
}

.switch label input[type="checkbox"] {
    opacity: 0;
    width: 0;
    height: 0;
}

.switch label input[type="checkbox"]:not(:checked),
.switch label input[type="checkbox"]:checked {
    position: absolute;
    pointer-events: none;
    opacity: 0;
}

.switch label .lever {
    position: relative;
    display: inline-block;
    margin-right: .625rem;
    vertical-align: middle;
    content: "";
    background-color: #E2E1E1;
    border-radius: .9375rem;
    width: 2.5rem;
    height: .9375rem;
    -webkit-transition: background .3s ease;
    transition: background .3s ease;
}

.switch label .lever:after {
    position: absolute;
    top: -.1875rem;
    left: -.3125rem;
    display: inline-block;
    content: "";
    background-color: #8C8B8B;
    border-radius: 1.3125rem;
    width: 1.3125rem;
    height: 1.3125rem;
    -webkit-transition: left .3s ease, background .3s ease, -webkit-box-shadow 1s ease;
    transition: left .3s ease, background .3s ease, -webkit-box-shadow 1s ease;
    transition: left .3s ease, background .3s ease, box-shadow 1s ease;
    transition: left .3s ease, background .3s ease, box-shadow 1s ease, -webkit-box-shadow 1s ease;
}

.switch label input[type="checkbox"]:checked + .lever {
    background-color: #a9a9a9;
}

.switch label input[type="checkbox"]:checked + .lever:after {
    left: 1.5rem;
    background-color: #000000;
}


.cstm-pagination {
    margin: 0 auto;
    display: table;
}

.cstm-pagination ul {
    font-size: 0;
}

.cstm-pagination ul li {
    display: inline-block;
    font-size: 12px;
}

.cstm-pagination ul li .page-numbers {
    font-size: 14px;
    line-height: 36px;
    display: block;
    width: 38px;
    height: 38px;
    text-align: center;
    border: 2px solid transparent;
    margin: 0 6px;
    border: 1px solid #e3e2e2;
    border-radius: 100%;
}

.cstm-pagination ul li .page-numbers:hover {
    border: 1px solid #000000;
    color: #000000;
}

.cstm-pagination ul li.active .page-numbers {
    border: 1px solid #000000;
    background-color: #000000;
    color: #fff;
}

.page-content .select2-container--bootstrap4 .select2-selection {
    border-radius: 0;
}


.ps__thumb-y {
    border-radius: 0;
}

.ps .ps__rail-y,
.ps .ps__rail-y {
    background-color: #eee;
    opacity: 0.6;
    width: 8px;
}

.ps .ps__rail-y:hover,
.ps .ps__rail-y:focus,
.ps .ps__rail-y.ps--clicking {
    background-color: #eee;
    opacity: 0.9;
    width: 15px;
}


.owl-stage {
    padding-left: 0 !important;
}


/* ============================================= */
.thankyou h1 {
    font-size: 38px;
    line-height: 45px;
    margin: 0 150px 30px 150px;
    text-align: center;
    color: #333;
    font-family: "Montserrat", sans-serif;
}

.thankyou .thankyou-cups {
    margin: 0 auto 30px;
    display: block;
}

.thankyou .button {
    margin: 30px auto;
    display: table;
}

.thankyou .total {
    margin-top: 30px;
    border: none;
    width: 100%;
    padding: 25px;
}

.thankyou .total h3 {
    margin: 0;
}

.thankyou .total .product-row {
    margin: 0;
}

.thankyou .sum ul li span {
    font-weight: bold;
}

.thk-total {
    text-align: right;
}

.cart-list .cart-row .thk-total {
    font-weight: bold;
}

.thankyou .cart-headings {
    padding: 10px 0;
}

.thankyou .sum {
    margin: 0;
}

.thankyou .total .product-row .product-desc {
    margin-top: 5px;
}

.thankyou .total .product-row .product-name {
    font-size: 14px;
}


.reset-password h1 {
    margin: 0 0 30px 0;
}

.forgot-password h1 {
    margin: 0 0 30px 0;
    text-align: center;
}

.forgot-password h3,
.forgot-password p {
    text-align: center;
}


.login h1 {
    margin: 0 0 30px 0;
}


.register h1,
.login h1 {
    text-align: center;
}

.register h1 {
    margin: 0 0 30px 0;
}

.register .sub-heading {
    text-align: center;
    margin: 0 0 25px 0;
}

.register .icon-name {
    top: 11px;
}

.register .icon-mail {
    top: 15px;
}

.register .icon-password {
    top: 10px;
}

.reset-password .icon-password {
    top: 10px;
}

.reset-password h1 {
    color: #000000;
}

.reset-password h3 {
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    line-height: 22px;
    font-weight: bold;
    margin: 0 0 10px 0;
}

.reset-password .back {
    margin: 30px auto 0;
    display: table;
}

.reset-password button {
    display: block;
    width: 100%;
}

.register button {
    display: block;
    width: 100%;
}

.register .exist-reg {
    text-align: center;
    margin: 40px 0 0 0;
}

.register .exist-reg span {
    display: block;
}

.register .exist-reg a {
    display: inline-block;
    text-decoration: underline;
    color: #969595;
    margin: 2px 0 0 0;
}

.register .exist-reg a:hover {
    text-decoration: none;
    color: #000000;
}

.switch label .lever {
    top: -1px;
}


.faq {
    background-repeat: no-repeat;
    background-position: right 90px;
    background-size: 150px;
    padding-bottom: 50px;
}

.faq h1 {
    color: #000000;
}

.faq .faq-category {
    margin: 0 0 30px 0;
}

.faq .faq-category:last-of-type {
    margin: 0 0 0 0;
}

.faq .faq-category h3 {
    margin: 0 0 15px 0;
    text-transform: uppercase;
    font-weight: 600;
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
    letter-spacing: .12rem;
}

.faq .question {
    background-color: #fff;
    cursor: pointer;
    padding: 14px 15px 14px 15px;
    position: relative;
    display: block;
    text-transform: uppercase;
    font-size: 13px;
}

.faq .question.collapsed {
    box-shadow: none;
    background-color: #fff;
}

.faq .question.active {
    background: #fafafa;
}

.faq .accordion {
    margin: 0 0 50px 0;
}

.faq .accordion .accordion-item {
    border-bottom: 1px solid #eaeaea;
}

.faq .accordion:last-of-type {
    margin: 0;
}

.faq .accordion .question:after {
    content: "-";
    position: absolute;
    top: 50%;
    right: 0;
    margin-top: -15px;
    font-size: 20px;
}

.faq .accordion .question.collapsed:after {
    content: "+";
    margin-top: -13px;
}

.faq .answer .answer-content {
    padding: 10px 15px 30px;
    opacity: .6;
}

.faq .faq-heading {
    font-size: 16px;
    line-height: 22px;
    font-weight: bold;
    margin: 0 0 10px 0;
    text-transform: uppercase;
}


.forgot-password h3 {
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    line-height: 22px;
    font-weight: bold;
    margin: 0 0 10px 0;
}

.forgot-password {
    background-repeat: repeat-y;
    background-position: bottom left;
    background-size: 150px;
}

.forgot-password .icon-mail {
    top: 15px;
}

.forgot-password .back {
    margin: 30px auto 0;
    display: table;
}

.forgot-password .back:hover {
    text-decoration: underline;
}

.forgot-password button {
    display: block;
    width: 100%;
}


.card-payment h1 {
    color: #000000;
}

.card-payment button {
    display: block;
    width: 100%;
}

.card-payment .back {
    margin: 0;
}


.empty-category {
    text-align: center;
}

.empty-category .btn {
    margin: 10px auto 0;
    display: table;
}

.empty-category h3 {
    margin: 0 0 0 0;
}

.empty-category img {
    margin: 0 auto 10px;
    display: block;
    height: 150px;
}


.textpage {
    background-repeat: no-repeat;
    background-position: right 90px;
    background-size: 100px;
}

.textpage h1 {
    color: #000000;
}

.textpage h3 {
    text-transform: uppercase;
    font-size: 16px;
    line-height: 22px;
    margin: 0 0 10px 0;
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
}


.contacts {
    position: relative;
    margin: -25px 0 0 0;
}

.contacts .map {
    position: relative;
    margin: 25px 0 0 0;
}

.contacts .map .gmaps {
    width: 100%;
    height: 500px;
}

.contacts h1 {
    margin: 0 0 20px 0;
}

.contacts h4 {
    font-size: 14px;
    line-height: 22px;
    margin: 0 0 10px 0;
    font-weight: bold;
    font-family: 'Roboto', sans-serif;
}

.contacts .contact-form .form-group {
    margin-bottom: 25px;
}

.contacts .contact-form {
    padding: 50px 0 20px 55px;
    border-left: 1px solid #DCDAD9;
    z-index: 2;
    position: relative;
}

.contacts .contact-form p {
    margin: 0 0 40px 0;
}

.contacts .contact-info {
    padding: 50px 0 0 0;
}

.contacts .contact-info .main-office {
}

.contacts .contact-info .main-office i {
    margin-right: 7px;
    color: #000000;
    width: 15px;
    text-align: center;
}

.contacts .contact-info .worktime {
}

.contacts .contact-info .worktime i {
    margin-right: 7px;
    color: #000000;
    width: 15px;
    text-align: center;
}

.contacts .contact-info .heading {
    margin: 0 0 20px 0;
}

.contacts .contact-info ul.contacts-list {
    padding: 10px 15px 0 0;
}

.contacts .contact-info ul.contacts-list li {
    margin: 0 0 10px 0;
}

.contacts .contact-info ul.contacts-list li i {
    margin-right: 7px;
    color: #000000;
    width: 15px;
    text-align: center;
}

.contacts .contact-info ul.contacts-list li a {
    display: block;
    color: #000000;
}

.contacts .contact-info ul li p {
    margin: 0;
}

.contacts .contact-info ul li span {
    font-weight: bold;
    color: #000000;
    display: block;
}

.contacts .offices {
    padding: 65px 0 60px 0;
}

.contacts .offices .office {
    padding: 0 0 20px 0;
    margin: 0 0 50px 0;
    border-bottom: 1px solid #DCDAD9;
}

.contacts .offices .office .office-city {
    font-size: 20px;
    line-height: 22px;
    text-transform: uppercase;
    font-weight: bold;
    margin: 0 0 5px 0;
}

.contacts .offices .office .office-city a {
    font-size: 12px;
    text-transform: initial;
    font-weight: lighter;
    margin-left: 15px;
    color: #75797D;
}

.contacts .offices .office ul li {
    margin: 9px 0;
}

.contacts .offices .office ul li i {
    margin-right: 7px;
    color: #000000;
    width: 15px;
    text-align: center;
}

.contacts .offices .heading {
    text-align: center;
    margin: 0 0 45px 0;
}


.favorites.products {
    padding-bottom: 0;
}

.favorites h1 {
    color: #000000;
}


.about-us .about-text {
    padding: 50px 0;
    text-align: center;
}

.about-us .about-text p {
    font-size: 16px;
    line-height: 24px;
}

.about-us .hero-area {
    padding: 120px 0;
}

.about-us .hero-area h1 {
    font-size: 50px;
    line-height: 60px;
    margin: 0 0 15px 0;
    color: #fff;
    text-align: center;
}


.testimonials {
    background-color: #FBFAF9;
}

.testimonials .testimonial {
    text-align: center;
}

.testimonials .testimonial .testimonial-img {
    border-radius: 100%;
    margin: 25px auto 10px;
    width: 60px;
    height: 60px;
    display: block;
}

.testimonials .testimonial .testimonial-author {
    margin: 0 0 10px 0;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: bold;
    color: #000000;
}

.testimonials .btn {
    margin: 15px auto 0;
    display: table;
}


.payment-bank {
    padding: 25px;
    border: 1px solid #e3e2e2;
    margin-top: 50px;
}

.payment-bank h3 {
    border-bottom: 1px solid #E3E2E2;
    padding: 0 0 15px 0;
    margin: 0 0 25px 0;
    color: #000000;
    font-size: 20px;
    line-height: 26px;
    font-weight: bold;
}

.payment-bank ul li {
    clear: both;
    border-top: 1px solid #e3e2e2;
    padding: 10px 0;
    display: table;
    width: 100%;
}

.payment-bank ul li:last-of-type {
    padding-bottom: 0;
}

.payment-bank ul li:first-of-type {
    border: 0;
    padding-top: 0;
}

.payment-bank ul li .lab {
    float: left;
}

.payment-bank ul li .val {
    float: right;
    font-weight: bold;
    color: #000000;
}

.gallery-text {
    background-color: #FBFAF9;
}

.gallery-text .sup-heading {
    margin: 0 0 10px 0;
    text-align: center;
    text-transform: uppercase;
    font-size: 12px;
}

.gallery-text .heading {
    margin-bottom: 30px;
}

.gallery-text .sub-heading {
    text-align: center;
    margin: 0 0 50px 0;
    text-transform: uppercase;
    font-size: 12px;
    line-height: 22px;
}

.gallery-text .text-block {
    margin: 20px 0;
}


.gallery .hero-area {
    padding: 120px 0;
}

.gallery .hero-area h1 {
    font-size: 50px;
    line-height: 60px;
    margin: 0 0 15px 0;
    color: #fff;
    text-align: center;
}

.short-contacts {
    text-align: center;
}

.short-contacts img {
    margin: 0 0 20px 0;
}

.short-contacts a {
    font-weight: bold;
}

.short-contacts .lab {
    font-weight: bold;
    color: #000000;
    margin: 0 0 7px 0;
}

.short-contacts .val {
    padding: 0 50px;
}


.consultants {
    padding-bottom: 30px;
}

.consultants .consultant {
    margin: 0 0 70px 0;
}

.consultants .consultant .consultant-content {
    padding: 0 30px;
}

.consultants .consultant .consultant-content.md-cl-pl {
    padding-left: 0;
}

.consultants .consultant .consultant-name {
    font-family: 'Roboto', sans-serif;
    font-size: 24px;
    line-height: 28px;
    color: #000000;
    margin: 0 0 7px 0;
}

.consultants .consultant .consultant-pos {
    text-transform: uppercase;
    color: #000000;
    font-size: 12px;
}

.consultants .consultant .consultant-desc {
    margin: 20px 0 0 0;
}


.testimonials {
    background-repeat: no-repeat;
    background-position: top left;
    background-size: 150px;
}

.mb-cart-heading {
    display: none;
}

.range-slider {
    position: relative;
    height: 70px;
}

.range-slider + .input-filters input {
    max-width: 35%;
    text-align: center;
    height: 25px;
    border-radius: 5px;
}

.range-slider + .input-filters .delimiter {
    letter-spacing: -1px;
}
.range-slider + .input-filters .apply-price-filter-btn {
    letter-spacing: -1px;
    background-color: #fff;
    border-radius: 5px;
    border: 1px solid #e3e2e2;
    padding: 5px 7.5px;
    color: lightgray;
    cursor: pointer;
}

.irs--round .irs-from, .irs--round .irs-to,
.irs--round .irs-single {
    background-color: #000000;
    border-radius: 0;
}

.irs--round .irs-from:before, .irs--round .irs-to:before,
.irs--round .irs-single:before {
    border-top-color: #000000;
}

.irs--round .irs-min, .irs--round .irs-max {
    border-radius: 0;
    color: #fff;
    background-color: #000000;
}

.irs--round .irs-bar {
    background-color: #000000;
}

.irs--round .irs-handle {
    border: 4px solid #000000;
}

.extra-controls {
    padding: 0 30px;
}

.extra-controls input {
    width: 100%;
    text-align: center;

    height: 30px;
    padding: 0 15px;
    background-color: #fff;
    border-radius: 0;
    border: 1px solid #e3e2e2;
    font-size: 14px;
}

.extra-controls input:focus {
    box-shadow: none;
    border-color: #000000;
}


/*Profile*/
.profile {
    position: relative;
    padding: 30px 0 100px 0;
}


.profile .profile-content {
    padding: 0 20px;
    z-index: 2;
    position: relative;
}

.profile .form-group {
    margin: 0 0 30px 0;
}


.profile-orders .order-row {
    padding: 30px 20px;
    border: 1px solid #DCDAD9;
    margin-bottom: 30px;
}

.profile-orders .order-row:last-of-type {
    margin-bottom: 0;
}

.profile-orders .view-order {
    color: #000000;
    font-size: 12px;
}

.profile .profile-content #addresses a {
    display: table;
    margin: 15px 0 0 0;
}

.profile-orders .order-num {
    font-family: 'Roboto', sans-serif;
    padding: 0 0 20px 0;
    font-size: 18px;
    line-height: 24px;
    font-weight: bold;
    color: #000000;
    text-transform: uppercase;
}

.profile .order-details:last-of-type {
    margin-bottom: 0;
}

.profile .order-details h3 {
    border-bottom: 1px solid #E3E2E2;
    padding: 0 0 15px 0;
    margin: 0 0 25px 0;
    color: #000000;
    font-size: 20px;
    line-height: 26px;
    font-weight: bold;
    text-transform: uppercase;
}

.profile .order-details .lab {
    color: #909090;
    text-transform: uppercase;
    font-size: 12px;
}

.profile .order-details .val {
    font-weight: bold;
    font-size: 14px;
    color: #000000;
}

.profile.profile-orders .order-details {
    padding: 0;
    border: none;
}

.profile .total {
    margin: 0 0 30px 0;
    width: 100%;
    border-bottom: 1px solid #ededed;
}

.profile.profile-address-list .order-details h3 {
    margin: 0;
    padding: 0 0 20px 0;
    border: 0;
}

.profile.profile-address-list .order-details {
    padding: 30px 20px;
}

.profile .order-details {
    margin: 0 0 30px 0;
    padding: 20px;
    border: 1px solid #e0dfde;
}

.profile-order .total .product-row .product-img {
    height: 80px;
}

.profile-order .cart-headings {
    padding-left: 0;
    padding-right: 0;
    margin: 0 25px;
}

.profile-order .order-products h3 {
    border-bottom: 1px solid #E3E2E2;
    padding: 0 0 15px 0;
    margin: 0 0 25px 0;
    color: #000000;
    font-size: 20px;
    line-height: 26px;
    font-weight: bold;
    text-transform: uppercase;
}

.profile-order .order-products {
    padding: 0 20px;
    border: 1px solid #ECECEC;
    margin: 0 0 30px 0;
}

.profile-order .order-products .product-row:last-of-type {
    border-bottom: 0;
}

.profile .order-products {
    border-top: 1px solid #ECECEC;
}

.profile .order-products .product-row {
    padding: 20px 0;
    border-bottom: 1px solid #ECECEC;
}

.profile .order-products .product-row .product-name {
    font-weight: bold;
    font-size: 14px;
    color: #000000;
    margin: 0 0 5px 0;
}

.profile .order-products .product-row .product-img {
    max-width: 80px;
}

.profile .order-products .product-row .lab {
    margin: 0 0 7px 0;
    color: #909090;
}

.profile .order-row .view-order i {
    margin-left: 3px;
}

.profile .order-products .product-row .product-desc {
    color: #909090;
}

.profile-address-list .btn {
    margin: 30px 0 0 0;
    display: table;
}

.profile-address-list .edit-address {
    display: block;
}

.profile-address-list .primary-address {
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
    color: #fff;
    padding: 2px 15px;
    background-color: #000000;
    float: right;
    font-size: 12px;
    text-transform: uppercase;
}

.profile .add-address {
    padding: 20px;
    background-color: #FBFAF9;
}

.profile .add-address h3 {
    border-bottom: 1px solid #E3E2E2;
    padding: 0 0 15px 0;
    margin: 0 0 25px 0;
    color: #000000;
    font-size: 18px;
    line-height: 24px;
    font-weight: bold;
}

.user-menu li {
    display: block;
    border-bottom: 1px solid #DCDAD9;
}

.user-menu li a:hover,
.user-menu li.active a {
    background-color: #fff;
    opacity: 1;
}

.user-menu li a {
    display: block;
    padding: 12px 10px;
    text-transform: uppercase;
    position: relative;
    color: #000000;
    font-weight: bold;
    opacity: 0.7;
}

.user-menu li a img {
    height: 20px;
    vertical-align: sub;
    margin-right: 7px;
    min-width: 25px;
}

.user-menu li a img.icn {
    display: inline-block;
}

.user-menu li a:hover img.icn {
    display: none;
}

.user-menu li a img.icn-hover {
    display: none;
}

.user-menu li a:hover img.icn-hover {
    display: inline-block;
}

.user-menu li a:hover {
    color: #000000;
}

.user-menu li a:after {
    content: "\f105";
    font-family: FontAwesome;
    font-size: 22px;
    position: absolute;
    top: 5px;
    right: 10px;
    color: #b5b5b5;
}

.checkout-modal .btn {
    display: block;
    width: 100%;
    padding: 0 20px;
    margin-bottom: 5px;
}

.checkout-modal .modal-content {
    padding: 15px 20px 15px;
}

.checkout-modal .close {
    display: table;
    width: 30px;
    margin: 5px 5px 0 auto;
    outline: none;
    transition: all .4s;
}

.checkout-modal .btn {
    display: block;
    width: 100%;
    padding: 0 20px;
}

.checkout-modal .button-primary:hover {
    background-color: #000000;
    color: #fff;
}

.checkout-modal .button-primary.focus,
.checkout-modal .button-primary:focus,
.checkout-modal .button-primary.disabled,
.checkout-modal .button-primary:disabled,
.checkout-modal .button-primary:not(:disabled):not(.disabled).active:focus,
.checkout-modal .button-primary:not(:disabled):not(.disabled):active:focus,
.checkout-modal .show > .button-primary.dropdown-toggle:focus,
.checkout-modal .button-primary:not(:disabled):not(.disabled).active,
.checkout-modal .button-primary:not(:disabled):not(.disabled):active,
.checkout-modal .show > .button-primary.dropdown-toggle {
    background-color: #000000;
    border-color: #000000;
    color: #ffff;
    box-shadow: none;
    box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, .5);
}

.checkout-modal .hint {
    padding: 20px 0;
    text-align: center;
    font-size: 14px;
}

.checkout-modal .hint img {
    vertical-align: bottom;
    margin: 0px auto 20px;
    width: 50px;
    display: block;
}

.carousel-control-prev-icon {
    background: url('../../images/icons/arrow-left.svg');
    background-size: 30px;
}

.carousel-control-next-icon {
    background: url('../../images/icons/arrow-right.svg');
    background-size: 30px;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
    width: 30px;
    height: 30px;
    background-position: center;
}

.carousel-item {
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 6px;
}

.age-gate {
    text-align: center;
}

.age-gate .modal-body {
    padding: 40px 30px;
}

.age-gate h5 {
    color: #000000;
    margin: 0 0 30px 0;
    font-size: 22px;
    line-height: 28px;
}

.age-gate img {
    margin: 0 auto 30px;
    display: block;
    height: 150px;
}

.age-gate .btn {
    margin: 0 5px;
    min-width: 130px;
}

.modal-backdrop {
    background-color: #000000;
}

.custom-calendar header {
    position: initial;
    padding: 0;
}

.custom-calendar.vdp-datepicker__calendar .cell.selected {
    background-color: #000000;
    color: #fff;
}

.custom-calendar.vdp-datepicker__calendar .cell:not(.blank):not(.disabled).day:hover,
.custom-calendar.vdp-datepicker__calendar .cell:not(.blank):not(.disabled).month:hover,
.custom-calendar.vdp-datepicker__calendar .cell:not(.blank):not(.disabled).year:hover {
    border: 1px solid #000000;
}

.grecaptcha-badge {
    bottom: 95px !important;
}

.page-content .select2-container--bootstrap4 .select2-selection {
    min-height: 44px;
    border-color: #e7e6e6;
}

.select2-container--bootstrap4 .select2-selection--single .select2-selection__rendered {
    line-height: calc(2.1em + .75rem);
}

.discount-text {
    padding: 25px 0;
    margin: 30px 0 0 0;
}

.discount-text h3 {
    text-transform: uppercase;
    font-size: 16px;
    line-height: 24px;
    margin: 0 0 10px 0;
    font-weight: bold;
}

.discount-text p {
    line-height: 24px;
    color: #75797D;
}


.cart-login {
    margin: 0 0 50px 0;
}

.cart-login h5 {
    font-weight: bold;
    margin: 0 0 17px 0;
    font-size: 14px;
    color: #000000;
}

.cart-login h5 a {
    color: #75797D;
    font-weight: normal;
    margin-left: 3px;
}

.cart-login h5 a:hover {
    text-decoration: underline;
}

.cart-login .forgot-password-link {
    display: table;
    margin: 10px 0 0 0;
    color: #000000;
    font-size: 12px;
}

.cart-login .forgot-password-link:hover {
    text-decoration: underline;
}

.make-profilе {
    margin: 0 0 20px 0;
    color: #75797D;
}

.make-profilе input[type="checkbox"] {
    opacity: 0;
    z-index: 1;
    cursor: pointer;
    position: absolute;
}

.make-profilе label {
    margin: 0;
    padding-right: 15px;
}

.make-profilе label::before {
    content: "";
    position: absolute;
    width: 17px;
    height: 17px;
    right: 0;
    border: 1px solid #cccccc;
    background-color: #fff;
}

.make-profilе label::after {
    content: "";
    position: absolute;
    width: 16px;
    height: 16px;
    right: 1px;
    top: 0px;
    font-size: 12px;
    text-align: center;
    color: #555555;
}

.make-profilе input[type="checkbox"]:checked + label::after {
    font-family: "FontAwesome";
    content: "\f00c";
}


.invoice-info {
    margin: 0 0 30px 0;
}

.invoice-info.invoice-open .invoice-toggle:after {
    transform: rotate(-180deg);
}

.invoice-info .invoice-toggle {
    margin: 0 0 20px 0;
    color: #000000;
    display: inline-block;
    position: relative;
    user-select: none;
    font-weight: bold;
}

.invoice-info .invoice-toggle:after {
    content: "\f107";
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: 20px;
    position: absolute;
    right: -19px;
    -webkit-transition: all .4s;
    transition: all .4s;
}


.profile-order .total .product-row .product-name {
    text-transform: uppercase;
    margin: 0 0 5px 0;
    display: inline-block;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
}

.profile-order .total .product-row .product-desc {
    font-size: 12px;
    line-height: 18px;
    color: #afafaf;
}


.docs .doc {
    padding: 10px 20px;
    border: 1px solid #dcdcdc;
    margin: 0 0 15px 0;
    display: block;
}

.docs .doc:hover {
    color: #000000;
}

.docs .doc .doc-name {
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 45px;
}

.docs .doc .doc-icon {
    height: 40px;
}

.docs .doc .dwl img {
    height: 30px;
}


#sync1 .item {
    background: #0c83e7;
    padding: 80px 0px;
    margin: 5px;
    color: #FFF;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    text-align: center;
}

#sync2 .item {
    background: #C9C9C9;
    padding: 10px 0px;
    margin: 5px;
    color: #FFF;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    text-align: center;
    cursor: pointer;
}

#sync2 .item h1 {
    font-size: 18px;
}

#sync2 .synced .item {
    background: #0c83e7;
}

.slider .owl-item {
    cursor: grab;
}

.navigation-thumbs .owl-item {
    cursor: pointer;
}

.category-holder {
    position: relative;
}

.category-holder:hover img {
    -webkit-filter: brightness(90%);
}

.category-holder:after {
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    position: absolute;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 60%, rgba(255, 255, 255, .6) 80%);
    pointer-events: none;
}

.category-holder a {
    display: block;
    text-align: center;
}

.category-holder .category-name {
    position: absolute;
    bottom: 40px;
    padding: 10px;
    width: 100%;
    z-index: 2;
    font-family: 'Montserrat', sans-serif;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 26px;
}

nav .search {
    display: none;
}

.close {
    -webkit-transition: -webkit-transform 0.6s ease-in-out;
    transition: transform 0.6s ease-in-out;
}

.close:hover {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
}

.filter-sticky {
    background-color: #fff;
    padding: 16px 0 13px 0;
    top: 100px;
    border-bottom: 1px solid #dedede;
}

.filter-sticky:before {
    content: "";
    z-index: 2;
    background-color: #fff;
    width: 15px;
    height: 100%;
    position: absolute;
    top: 0;
    left: -15px;
}

.filter-sticky:after {
    content: "";
    position: absolute;
    bottom: -16px;
    left: -15px;
    background-color: #fff;
    height: 15px;
    width: 100%;
    z-index: 2;
}

.alert-danger {
    color: #000000;
    background-color: #f1eced;
    border-color: #f3d9dc;
}

.main-msg {
    margin: 0 0 20px 0;
}

.main-msg .lab {
    position: relative;
    padding: 0 15px 0 25px;
    background-color: #000000;
    text-transform: uppercase;
    font-weight: bold;
    color: #fff;
    line-height: 44px;
    margin-right: 35px;
}

.main-msg .lab:before {
    content: '';
    position: absolute;
    right: -50px;
    top: 0;
    width: 0;
    height: 0;
    border-left: 0 solid transparent;
    border-right: 50px solid transparent;
    border-top: 44px solid #000000;
    clear: both;
}

.main-msg .val p {
    display: inline-block;
    margin: 0;
    padding: 0;
}

.category-title {
    background: #f4f4f3;
    margin-bottom: 0;
    padding: 30px 15px;
    border-bottom: 1px solid #dedede;
}

.category-title h1 {
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 20px;
    word-break: break-all;
    font-size: 25px;
    letter-spacing: 0.12em;
    font-family: 'Montserrat', sans-serif;
    line-height: 34px;
}

.category-title .text-holder.stripped {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.category-title .read-more-toggle {
    cursor: pointer;
    display: inline-block;
}

.p-fix-0 {
    padding: 0;
}

#checkout-modal .button {
    display: block;
    width: 100%;
    margin-bottom: 10px;
    text-align: center;
    padding: 0 10px 0 10px;
    height: 36px;
    line-height: 35px;
}

#checkout-modal .button.button-primary:active,
#checkout-modal .button.button-primary:focus {
    background: #fff !important;
    color: #333 !important;
}

.carousel-control-next, .carousel-control-prev {
    width: 5%;
    border: 0;
    background: none;
}

.products-list .owl-carousel .owl-nav {
    position: inherit;
    top: 0;
    right: 0;
}

.ck-content h2, .ck-content h3, .ck-content h4, .ck-content h5, .ck-content h6 {
    font-family: 'Montserrat', sans-serif;
}
.header-msg{
    text-align: center;
    background: darkred!important;
    font-family: 'Montserrat',sans-serif;
    font-size: 14px;
    color: white;
}
.left-auto{
    left: auto!important;
}