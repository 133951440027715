@font-face {
    font-family: 'Highest';
    font-style: normal;
    src: url('../../fonts/highest.ttf') format('truetype')
}

@font-face {
    font-family: 'Hand Of Sean';
    font-style: normal;
    src: url('../../fonts/sean_johnson_hand_of_sean_pro.otf') format('opentype')
}

@font-face {
    font-family: 'Times';
    font-style: normal;
    src: url('../../fonts/times.ttf') format('truetype')
}

@font-face {
    font-family: 'Franklin Gothic';
    font-style: normal;
    src: url('../../fonts/franklin_gothic.ttf') format('truetype')
}

.add-engraving input[type="text"] {
    color: black;
    text-align: center;
    font-size: 33px;
}

.add-engraving input[type="text"]::placeholder {
    font-size: 16px;
    line-height: 1.5;
}

.add-engraving input[type="text"].highest, .engraving-container.highest .engraving-line {
    font-family: 'Highest';
}

.add-engraving input[type="text"].hand_of_sean, .engraving-container.hand_of_sean .engraving-line {
    font-family: 'Hand Of Sean';
}

.add-engraving input[type="text"].times, .engraving-container.times .engraving-line {
    font-family: 'Times';
}

.add-engraving input[type="text"].franklin_gothic, .engraving-container.franklin_gothic .engraving-line {
    font-family: 'Franklin Gothic';
}

.engraving-lines {
    top: 0;
    height: 100%;
    text-align: center;
    left: 0;
    width: 100%;
    padding: 11% 24% 13% 24%;
    --font-multiplier: 1;
    overflow: hidden;
}

.engraving-lines-1 .engraving-line {
    font-size: calc(34px * var(--font-multiplier));
}

.engraving-lines-2 .engraving-line {
    font-size: calc(17px * var(--font-multiplier));
}
.engraving-lines-3 .engraving-line {
    font-size: calc(13px * var(--font-multiplier));
}

.engraving-line {
    font-size: 16px;
}